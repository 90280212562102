import { Component, Input, OnInit } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-approval-from-panchayat',
  templateUrl: './approval-from-panchayat.component.html',
  styleUrls: ['./approval-from-panchayat.component.scss']
})
export class ApprovalFromPanchayatComponent implements OnInit {

  @Input() data : any;

  @Input() isEditable : any;

  public gpAprovalNumber : string;
  public gpAprovalDate : Date;
  public panchayat : number;
  public panchayats: any[];

  public maxDate = new Date().getTime();

  constructor(private applicationService: ApplicationService) { }

  ngOnInit(): void {
    this.getAllPanchayats();
  }

  reset(){
    this.gpAprovalNumber = null;
    this.gpAprovalDate = null;
    this.panchayat = null;
    this.panchayats = null;
  }


  getAllPanchayats(){
    this.applicationService.fetchAllPanchayats().subscribe(res =>{
      this.panchayats =  res;
    })
  }

  getPayload(){
    let payload = {};
    payload['gpAprovalNumber'] = this.gpAprovalNumber;
    payload['gpAprovalDate'] = this.gpAprovalDate;
    payload['panchayat'] = this.panchayat;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.gpAprovalNumber = this.data.gpAprovalNumber;
    this.gpAprovalDate = this.data.gpAprovalDate;
    this.panchayat = this.data.panchayat;
   }

}
