import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-electricity-connection',
  templateUrl: './electricity-connection.component.html',
  styleUrls: ['./electricity-connection.component.scss']
})
export class ElectricityConnectionComponent implements OnInit {

  @Input() data : any;
  @Input() isEditable : any;

  public tempOrderNo : string;

  public tempOrderDate : Date;

  public permOrderNo : string;

  public permOrderDate : Date;

  public maxDate = new Date().getTime();

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.tempOrderNo = null;
    this.tempOrderDate = null;
    this.permOrderNo = null;
    this.permOrderDate = null;
  }

  getPayload(){
    let payload = {};
    payload['bescomTempNumber'] = this.tempOrderNo;
    payload['bescomTempDate'] = this.tempOrderDate;
    payload['bescomPermNumber'] = this.permOrderNo;
    payload['bescomPermDate'] = this.permOrderDate;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.tempOrderNo = this.data.bescomTempNumber;
    this.tempOrderDate = this.data.bescomTempDate;
    this.permOrderNo = this.data.bescomPermNumber;
    this.permOrderDate = this.data.bescomPermDate;
   }

}
