<div  class="head">
	{{data?.message}}
</div>
<div class="row">
   
       
     <div class="col-sm-4">
        <label >Comments</label>
        <textarea  class="form-control" 
        style=" min-width:500px; max-width:100%;min-height:50px;height:100%;width:100%;" placeholder="Enter Comments/Observations/Recommendations (if any)"
        [(ngModel)]="comment"></textarea> 
    </div>
</div>
<div class="button" >
	<button  class="btn negative-btn" mat-button (click)="onCancelClicked()">Cancel</button>
	<button mat-button (click)="onOkClicked()" class="btn btn-primary" cdkFocusInitial>Ok</button>
  </div>