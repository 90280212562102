<div class="row content-area">
    <div class="title">1. Sanctions of temporary connection / ತಾತ್ಕಾಲಿಕ ಮಂಜೂರಾತಿ -</div>
    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Order No / ಆದೇಶ ಸಂಖ್ಯೆ :</label>
            <input type="text" [(ngModel)]="tempOrderNo" class="form-textbox" [readonly] = "(data && data.bwssbTempNumber) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Date / ದಿನಾಂಕ:</label>
            <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="tempOrderDate" class="form-textbox" [readonly] = "(data && data.bwssbTempDate) && !isEditable"/>
        </div>
    </div>

    <div class="title">2. Sanctions of permanent connection / ಖಾಯಂ ಮಂಜೂರಾತಿ   -</div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Order No / ಆದೇಶ ಸಂಖ್ಯೆ :</label>
            <input type="text" [(ngModel)]="permOrderNo" class="form-textbox" [readonly] = "(data && data.bwssbPermNumber) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Date / ದಿನಾಂಕ:</label>
            <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="permOrderDate" class="form-textbox"  [readonly] = "(data && data.bwssbPermDate) && !isEditable" />
        </div>
    </div>
</div>

