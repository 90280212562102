import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-completion-certificate',
  templateUrl: './completion-certificate.component.html',
  styleUrls: ['./completion-certificate.component.scss']
})
export class CompletionCertificateComponent implements OnInit {

  @Input() data : any;
  @Input() isEditable : any;

  public completionNo : string;

  public completionDate : Date;

  public issuingAuthority : string;

  public maxDate = new Date().getTime();

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.completionNo = null;
    this.completionDate = null;
    this.issuingAuthority = null;
  }

  getPayload(){
    let payload = {};
    payload['completionNumber'] = this.completionNo;
    payload['completionDate'] = this.completionDate;
    payload['completionAuth'] = this.issuingAuthority;
    return payload;
  }


  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.completionNo = this.data.completionNumber;
    this.completionDate = this.data.completionDate;
    this.issuingAuthority = this.data.completionAuth;
   }

}
