import { Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { CommencementCertificateComponent } from '../miscellaneous/commencement-certificate/commencement-certificate.component';
import { CompletionCertificateComponent } from '../miscellaneous/completion-certificate/completion-certificate.component';
import { CopyLastPaidReceiptComponent } from '../miscellaneous/copy-last-paid-receipt/copy-last-paid-receipt.component';
import { CourtCasePendingComponent } from '../miscellaneous/court-case-pending/court-case-pending.component';
import { DetailsOfLoanAvailedComponent } from '../miscellaneous/details-of-loan-availed/details-of-loan-availed.component';
import { ElectricityConnectionComponent } from '../miscellaneous/electricity-connection/electricity-connection.component';
import { OccupancyCertificateComponent } from '../miscellaneous/occupancy-certificate/occupancy-certificate.component';
import { WaterConnectionComponent } from '../miscellaneous/water-connection/water-connection.component';
import { ApprovalFromPanchayatComponent } from './approval-from-panchayat/approval-from-panchayat.component';
import { ApprovalOfBbmpComponent } from './approval-of-bbmp/approval-of-bbmp.component';
import { BbdApprovedComponent } from './bbd-approved/bbd-approved.component';
import { ConstructWithoutApprovalComponent } from './construct-without-approval/construct-without-approval.component';
import { ConversionOfOrderComponent } from './conversion-of-order/conversion-of-order.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationService } from 'src/services/application.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-details-of-approval',
  templateUrl: './details-of-approval.component.html',
  styleUrls: ['./details-of-approval.component.scss']
})
export class DetailsOfApprovalComponent implements OnInit {
  
  public menus: any = [
    {
      "name": "Details of Approval",
      "code": "details_approval",
      "active": true,
      "default": true,
      "sub_menus":[
        {
          name:'Constructed with approval', 
          type: 'child',
          code: "constructed_with_approval"
        },
        {
          name:'BDA Approved Layout',
          type: 'child',
          code: "bda_approved_layout"
        },
        {
          name:'Approval from Panchayat',
          type: 'child',
          code: "panchayat_approval"
        }, 
        {
          name:'Conversion order of DC',
          type: 'child',
          code: "conversion_order_dc"
        },
        {
          name:'Approval from BBMP',
          type: 'child',
          code: "approval_bbmp"
        }
      ]
    },
    {
      name:'Commencement Certificate',
      type:'link',
      code: "commencement_certificate"
    },
    {
      name:'Completion Certificate',
      type:'link',
      code: "completion_certificate"
    }, 
    {
      name:'Occupancy Certificate',
      type:'link',
      code: "occupancy_certificate"
    }, 
    {
      name:'Electricity connection',
      type:'link',
      code: "electricity_connection"
    },
    {
      name:'Water connection',
      type:'link',
      code: "water_connection"
    }, 
    {
      name:'Details of loan availed',
      type:'link',
      code: "loan_details_availed"
    },
    {
      name:'Court case pending/stay order',
      type:'link',
      code: "court_case_stay_order"
    }
];

  public data: any;
  public isEditable: any;

  @ViewChild(ConstructWithoutApprovalComponent) constructWithoutApprovalComponent;
  @ViewChild(BbdApprovedComponent) bbdApprovedComponent;
  @ViewChild(ApprovalFromPanchayatComponent) approvalFromPanchayatComponent;
  @ViewChild(ConversionOfOrderComponent) conversionOfOrderComponent;
  @ViewChild(ApprovalOfBbmpComponent) approvalOfBbmpComponent;
  @ViewChild(CommencementCertificateComponent) commencementCertificateComponent;
  @ViewChild(CompletionCertificateComponent) completionCertificateComponent;
  @ViewChild(CourtCasePendingComponent) courtCasePendingComponent;
  @ViewChild(DetailsOfLoanAvailedComponent) detailsOfLoanAvailedComponent;
  @ViewChild(ElectricityConnectionComponent) electricityConnectionComponent;
  @ViewChild(OccupancyCertificateComponent) occupancyCertificateComponent;
  @ViewChild(WaterConnectionComponent) waterConnectionComponent;

  public selectedMenu: any = {};
  private applicantNo: any;
  private applicantId: any;

  @Output() nextScreenCallback = new EventEmitter<any>();
  
  constructor(private applicationService: ApplicationService, public dialog: MatDialog,
    private _snackBar: MatSnackBar, private route: ActivatedRoute, 
    private jccEncodeDecoder: JccnoEncodeDecodeService) {
      
    }

  public fetchApplicationData = () => {
    this.applicationService.fetchApplicantByApplicationNo(this.applicantNo).subscribe(res=>{
        console.log("property details", res);
        this.applicantId = res.id;
        this.data = res;
        if(res['oneTimeEdit'] != null) {
          this.isEditable = res['oneTimeEdit'];
        }
    })
  }

  ngOnInit(): void {
    this.selectedMenu = this.menus[0]['sub_menus'][0];
    let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if(encodedNo) {
      this.applicantNo = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicationData();
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res =>{
        this.data = res;
        if(res['oneTimeEdit'] != null) {
          this.isEditable = res['oneTimeEdit'];
        }
    })
  }

  reset(){
    this.constructWithoutApprovalComponent.reset();
    this.bbdApprovedComponent.reset();
    this.approvalFromPanchayatComponent.reset();
    this.conversionOfOrderComponent.reset();
    this.conversionOfOrderComponent.reset();
    this.commencementCertificateComponent.reset();
    this.completionCertificateComponent.reset();
    this.occupancyCertificateComponent.reset();
    this.electricityConnectionComponent.reset();
    this.waterConnectionComponent.reset();
    this.detailsOfLoanAvailedComponent.reset();
    this.courtCasePendingComponent.reset();
  }

  private validateAndConstructPayload = () => {
   let contructionWithoutAprovalPayload = this.constructWithoutApprovalComponent.getPayload();
   let bdaPayload = this.bbdApprovedComponent.getPayload();
   let panchayatPayload = this.approvalFromPanchayatComponent.getPayload();
   let conversionDcPayload = this.conversionOfOrderComponent.getPayload();
   let bbmpAprovalPayload = this.approvalOfBbmpComponent.getPayload();
   let commencementPayload = this.commencementCertificateComponent.getPayload();
   let completionPayload = this.completionCertificateComponent.getPayload();
   let occupancyPayload = this.occupancyCertificateComponent.getPayload();
   let electricityPayload = this.electricityConnectionComponent.getPayload();
   let waterPayload = this.waterConnectionComponent.getPayload();
   let detailsOfLoanPayload = this.detailsOfLoanAvailedComponent.getPayload();
   let casePendingPayload = this.courtCasePendingComponent.getPayload();
   let payload = Object.assign({}, contructionWithoutAprovalPayload, bdaPayload, panchayatPayload, conversionDcPayload, bbmpAprovalPayload,
     commencementPayload, completionPayload, occupancyPayload, electricityPayload, waterPayload,
     detailsOfLoanPayload, casePendingPayload);
   return payload;
  }

  public onItemClicked = (event: any) => {
    this.selectedMenu = event;
  }

  onSubmit = () => {
    this.openDialog();
  }

  openSnackbar(message: string, action?: string){
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '360px';
    dialogConfig.data = "Are you sure want to submit this record ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
        if(res){
          if(this.applicantId) {
            this.updateApplicantSiteApprovalApiCall(this.applicantId);
            return;
          }
          this.saveApplicantPropertyDetailsApiCall();
        }
    })
  }

  saveApplicantPropertyDetailsApiCall = () => {
    if(!this.applicantId) {
      this.openSnackbar("Application not created. please fill Basic Details Form");
      return;
    }
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.saveApplication(payload).subscribe(res =>{
        this.data = res;
        this.setEditable(res);
        this.openSnackbar("Successfully Saved", "Dismiss");
      }, err=>{
          console.log(err);
          this.openSnackbar(err.message, "Dismiss");
      })
    }
    catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }

  private setEditable = (data: any): void => {
    if(data['oneTimeEdit'] != null) {
      this.isEditable = data['oneTimeEdit'];
    }
  }

  updateApplicantSiteApprovalApiCall = (applicantId: any) => {
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.saveSiteDetails(applicantId, payload).subscribe(res =>{
        this.data = res;
        this.setEditable(res);
        this.openSnackbar("Successfully Updated", "Dismiss");
      }, err => {
          console.log(err);
          this.openSnackbar(err?.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }
}
