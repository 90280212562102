<div class="row content-area">
    <div class="title">BDA approved layout / ಬಿಡಿಎ ಮಂಜೂರಾದ ಬಡಾವಣೆ :</div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row">
                    <label class="form-label">L.P No/ಎಲ್.ಪಿ. ಸಂಖ್ಯೆ :</label>
                    <input type="text" [(ngModel)]="bdaLayoutAprovalNumber" class="form-textbox" [readonly] = "(data && data.bdaLayoutAprovalNumber) && !isEditable"/>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row">
                    <label class="form-label">Date of Approval / ಅನುಮೋದನೆಯ ದಿನಾಂಕ :</label>
                    <input type="date" onkeydown="return false" [(ngModel)]="bdaLayoutAprovalDate"
                    max="{{ maxDate |date:'yyyy-MM-dd'}}"  class="form-textbox" [readonly] = "(data && data.bdaLayoutAprovalDate) && !isEditable"/>
                </div>
            </div>

            <div class="title">Building Plan approved by BDA / ಬಿಡಿಎ ಅನುಮೋದಿಸಿದ ಕಟ್ಟಡದ ನಕ್ಷೆ :</div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row">
                    <label class="form-label">L.P No/ಎಲ್.ಪಿ. ಸಂಖ್ಯೆ :</label>
                    <input type="text" [(ngModel)]="bdaAprovalNumber" class="form-textbox" [readonly] = "(data && data.bdaAprovalNumber) && !isEditable"/>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row">
                    <label class="form-label">Date of Approval / ಅನುಮೋದನೆಯ ದಿನಾಂಕ :</label>
                    <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="bdaAprovalDate"  class="form-textbox" [readonly] = "data && data.bdaAprovalDate" />
                </div>
            </div>
</div>
        



