import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-construct-without-approval',
  templateUrl: './construct-without-approval.component.html',
  styleUrls: ['./construct-without-approval.component.scss']
})
export class ConstructWithoutApprovalComponent implements OnInit {

  @Input() data: any;
  
  @Input() isEditable: any;

  public isConstructWithoutApproval = 'yes';

  constructor() { }

  ngOnInit(): void {
    
  }

  reset(){
    this.isConstructWithoutApproval = null;
  }

  getPayload(){
    let payload = {};
    payload['constNoAproval'] = this.isConstructWithoutApproval == 'yes' ? true : this.isConstructWithoutApproval == 'no'? false : null;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    if(this.data.constNoAproval == null) {
      return;
    }
    this.isConstructWithoutApproval = this.data.constNoAproval ? 'yes' : 'no';
   }

}
