import { Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { UserService } from 'src/services/user-service/user.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.scss']
})
export class LoginButtonComponent implements OnInit {
  @Input() name: string; 
  @Input() color: string;
  @Input() loginPath: string;
  @Input() disabled?: boolean = false;

  isUserLoggedIn : any;
  style: string;

  constructor(private router: Router, private userService: UserService, private authService: AuthService, 
    private jccEncodeDecoder: JccnoEncodeDecodeService, private storageService:StrorageService) { }

  ngOnInit(): void {
  }

 
  onLoginClick(){
    if(this.disabled) {
      return;
    }
    this.router.navigate([this.loginPath]);
  }

  isHelpdeskUser(){
    return this.userService.getUserType() == this.userService.HELPDESK_USER;
  }

  isOnlineApplicationUser(){
    return this.userService.getUserType() == this.userService.ONLINE_APPLICATION_USER;
  }

  isCommitteeUser(){
    return this.userService.getUserType() == this.userService.COMITTEE_USER;
  }

  isScrutinyUser(){
    return this.userService.getUserType() == this.userService.SCRUTINY_USER;
  }
  isFieldUser(){
    return this.userService.getUserType() == this.userService.FIELD_VISIT_USER;
  }
  addStyle(){
    this.style='font-size:20px';
  }
}
