import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { ApiLoaderComponent } from './api-loader/api-loader.component';

@Injectable()
export class ApiLoaderService {
  private opened = false;
  loading: boolean;
  private overlayRef: OverlayRef = undefined;

  constructor(private overlay: Overlay) {}

  show(): void {
    if(this.overlayRef) {
      return;
    }
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      hasBackdrop: true,
    });
    this.overlayRef.attach(new ComponentPortal(ApiLoaderComponent));
  }

  hide() {
    if(this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = undefined;
    }
  }
}