<div class="row content-area">

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row">
         <label class="form-label">Details of any Court cases pending/interim stay order /  ಯಾವುದೇ ನ್ಯಾಯಾಲಯದಲ್ಲಿ ಪ್ರಕರಣಗಳು ಬಾಕಿ ಉಳಿದಿವೆಯೇ / ಮಧ್ಯಂತರ ತಡೆ ಆದೇಶ </label>
        </div>
         </div>
         <div class="col-sm-12 col-md-12">
             <div class="row radio-row">
                 <input id="single" type="radio" [disabled] = "(data && data.isCasePending) && !isEditable" [(ngModel)]="isCasePending" value="yes" name="isCasePending" />
                 <label for="single" class="radio-text">Yes / ಹೌದು</label>
             </div>
         </div>
     
         <div class="col-sm-12 col-md-12">
             <div class="row radio-row">
                 <input id="joint" type="radio" [disabled] = "(data && data.isCasePending) && !isEditable" [(ngModel)]="isCasePending" value="no" name="isCasePending"/>
                 <label for="joint" class="radio-text">No / ಇಲ್ಲ</label>
             </div>
         </div>

    <div class="col-sm-12 col-md-12" [hidden]="isCasePending == 'no'">
        <div class="row application-form-row ">
            <label class="form-label">Name of the court / ನ್ಯಾಯಾಲಯದ ಹೆಸರು :</label>
            <input type="text" [(ngModel)]="courtName" class="form-textbox" [readonly] = "(data && data.courtName) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12"  [hidden]="isCasePending == 'no'">
        <div class="row application-form-row ">
            <label class="form-label">Case No / ಪ್ರಕರಣ ಸಂಖ್ಯೆ. :</label>
            <input type="text" [(ngModel)]="caseNo" class="form-textbox" [readonly] = "(data && data.caseNumber) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12"  [hidden]="isCasePending == 'no'">
        <div class="row application-form-row ">
            <label class="form-label">Present Status / ಪ್ರಸ್ತುತ ಸ್ಥಿತಿ :</label>
            <input type="text" [(ngModel)]="presentStatus" class="form-textbox" [readonly] = "(data && data.caseStatus) && !isEditable"/>
        </div>
    </div>
</div>





