<mat-card>
    <div class="row">
        <div class="col-sm-3 ">
            <mat-form-field appearance="fill">
                <mat-label>From date</mat-label>
                <input matInput [(ngModel)]="fromDate" [max]="toDate ? toDate :maxDate" (dateInput)="onFromDateChange($event)" [matDatepicker]="fromDatePicker" readonly>
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker ></mat-datepicker>
              </mat-form-field>
        </div>

        <div class="col-sm-3">
            <mat-form-field appearance="fill">
                <mat-label>To date</mat-label>
                <input matInput [(ngModel)]="toDate" [min]="fromDate ? fromDate: maxDate " [max]="maxDate" (dateInput)="onToDateChange($event)"  [matDatepicker]="toDatePicker" readonly>
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
              </mat-form-field>
        </div>

  
    </div>

      
    <google-chart #chart
    [type]="type"
    [data]="data"
    [columns]="columnNames"
    [options]="options"
    [width]="width"
    [height]="height">
 </google-chart>

 <!-- <highcharts-chart
   [Highcharts] = "highcharts" 
   [options] = "chartOptions" 
   [callbackFunction]="chartCallback"
   [(update)]="updateFromInput"
   [oneToOne]="true"
   style = "width: 100%; height: 400px; display: block;">
</highcharts-chart> -->
</mat-card>
