import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldVisitUserLoginComponent } from './field-visit-user-login/field-visit-user-login.component';
import { FieldVisitUserDashboardComponent } from './field-visit-user-dashboard/field-visit-user-dashboard.component';
import { FieldVisitUserActivityLogComponent } from './field-visit-user-activity-log/field-visit-user-activity-log.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../material.module';
import { CommentDialogService } from '../scrutiny-user/scrutiny-preview/comment-dialog/services/comment-dialog.service';
import { SharedModule } from '../shared/shared.module';
import { FieldVisitWorkbenchComponent } from './field-visit-workbench/field-visit-workbench.component';
import { WorkbenchCommentDialogComponent } from './field-visit-workbench/workbench-comment-dialog/workbench-comment-dialog.component';
import { WorkbenchService } from './field-visit-workbench/workbench-comment-dialog/workbench.service';
import { FieldVisitActivityLogViewComponent } from './field-visit-activity-log-view/field-visit-activity-log-view.component';


const sharedComponents = [FieldVisitUserLoginComponent, FieldVisitUserDashboardComponent, 
  FieldVisitUserActivityLogComponent,FieldVisitWorkbenchComponent,WorkbenchCommentDialogComponent];

@NgModule({
  declarations: [...sharedComponents, FieldVisitWorkbenchComponent, FieldVisitActivityLogViewComponent],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    CommonModule,
    SharedModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule
  ],
  providers: [WorkbenchService,WorkbenchCommentDialogComponent],
  entryComponents: [...sharedComponents],
  exports: [...sharedComponents]
})
export class FieldVisitUserModule { }
