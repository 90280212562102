import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Input() public menus: any = [];
  @Output() public onMenuClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  openNextTab = (menu: any, menus: any) => {
    if(menu['sub_menus'] && menu['sub_menus'].length > 0) {
      menus = menu['sub_menus'];
      menu = menus[0];
    }
    for(let i = 0;i < menus.length;i++) {
      if(menu['code'] == menus[i]['code']) {
        menus[i]['active'] = true;
      } else {
        menus[i]['active'] = false;
      }
    }
    this.onMenuClick.emit(menu);
  }
}
