import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CommentDialogService } from 'src/app/scrutiny-user/scrutiny-preview/comment-dialog/services/comment-dialog.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { WorkbenchCommentDialogComponent } from './workbench-comment-dialog/workbench-comment-dialog.component';

@Component({
  selector: 'app-field-visit-workbench',
  templateUrl: './field-visit-workbench.component.html',
  styleUrls: ['./field-visit-workbench.component.scss']
})
export class FieldVisitWorkbenchComponent implements OnInit {
  public application_id : any;
  applicationData;
  selectedPanchayat = '';
  message:string;
  comment:string;
  status:string;
  public faTimes = faTimes;
  public isLoading=false;
  selectedVillages: any;
  public commentTypes: any = [];
  public fieldVisitStatusList: any = [];
    public title: any;
    approved_filter: any;
    public disable:boolean=true;
  createdAt: any;
  date: any;
   
    
    constructor(private applicationService: ApplicationService,private route: ActivatedRoute, 
      private _snackBar: MatSnackBar, private router: Router,
      private workbenchDialogService: WorkbenchCommentDialogComponent,
      private confirmDialogService: ConfirmDialogService ) { 
      this.application_id = this.route.snapshot.queryParams['application_id'];
      this.title=this.route.snapshot.queryParams['title'];
      console.log("title", this.title);
      console.log("jcc", this.application_id);
    }
  
    ngOnInit(): void {
      this._fetchDataByApplicationNo();
    }
  
    private _fetchDataByApplicationNo() {
      this.applicationService.fetchApplicantById(this.application_id).subscribe(res=>{
        console.log("app",res)
        this.applicationData = res;
        this.fetchAllPanchayats();
        this.fetchAllCommentTypes(res);
        this.fetchAllFieldVisitStatusList();
       })
    }
  
    close() {
      this.selectedVillages=this.route.snapshot.queryParams['village'];
      this.router.navigate(['/field-visit-user-dashboard']);
       
    }
    cancelClick(){
      this.close();
    }
  
    private fetchAllPanchayats(){
      this.applicationService.fetchAllPanchayats().subscribe(res=>{
        if(this.applicationData &&  this.applicationData.panchayat && res){
          this.selectedPanchayat = res[this.applicationData.panchayat-1]?.name;
        }
      })
    }

    private fetchAllFieldVisitStatusList(){
      this.applicationService.getFieldVisitStatus().subscribe(res=> {
        for(let i = 0;i < res.length;i++) {
            res[i].checked = false;
            this.fieldVisitStatusList.push(res[i]);
        }
      })
    }
  
    private fetchAllCommentTypes(data: any){
      this.applicationService.getScrutinyUserCommentTypeRef().subscribe(res=>{
        this.commentTypes = res.data;
        let selectedCommentTypes = data.scrutinyUserCommentTypes;
        for(let i = 0;i < this.commentTypes.length;i++) {
          let isChecked = false;
          for(let j = 0;j < selectedCommentTypes.length;j++) {
              if(this.commentTypes[i]['messageId'] == selectedCommentTypes[j]['messageId']) {
                  isChecked = true;
                  break;
              }
          }
          this.commentTypes[i].checked = isChecked;
        }
      })
    }
  
    encloPage() {
      let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
      window.open(fullURL+'#/preview-enclosure?application_id='+this.application_id, '_blank');
    }
  
    clickBack(){
      window.history.back();
    }

    saveFieldVisitDetails = () => {
      let payload = {};
      payload['comment'] = this.comment;
      let fieldStatusIds = [];
      for(let i = 0;i < this.fieldVisitStatusList.length;i++) {
        if(this.fieldVisitStatusList[i].checked) {
          fieldStatusIds.push(this.fieldVisitStatusList[i].statusId);
        }
      }
      payload['fieldVisitStatusIds'] = fieldStatusIds;
      console.log(payload);
        let options = {};
        options['title'] = 'Confirm';
        options['cancelText'] = 'CANCEL';
        options['confirmText'] = 'OK';
        options['message'] = 'Application Scrutinized & Post field visit, furthered for committee.';
  
        this.confirmDialogService.openDialog(options);
        this.confirmDialogService.confirmed().subscribe(confirmed => {
          if(confirmed) {
            this.applicationService.saveFieldVisit(this.application_id,payload).subscribe(res => {
              this.clickBack();
            })
          }
        });
    }
   
    checkValue($event:any){
         this.message
    }

}
