<h1 mat-dialog-title>An error has occurred!</h1>
<div mat-dialog-content>
	<p class="error-message">
		{{data?.message}}
	</p>
</div>
<div mat-dialog-actions>
	<button mat-raised-button mat-dialog-close color="primary">
    Close
  </button>
</div>