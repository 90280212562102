import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user-service/user.service';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { RoleConfigService } from '../shared/config/role/role.config.service';

@Component({
  selector: 'app-audit-helpdesk-login',
  templateUrl: './audit-helpdesk-login.component.html',
  styleUrls: ['./audit-helpdesk-login.component.scss']
})
export class AuditHelpdeskLoginComponent implements OnInit {

  public mobile: number;

  public password: string;

  faEye = faEye;

  faEyeSlash = faEyeSlash;

  passwordVisibility = 'password';

  constructor(private router:Router, private authService: AuthService, private userService: UserService, 
    private _snackBar: MatSnackBar, private roleConfig: RoleConfigService) { }

  ngOnInit(): void {
  }

  onSubmit(){
    if(!this.mobile){
      return this.openSnackbar('Mobile number field must not be empty', 'Dismiss');
    }
    if(!this.password){
      return this.openSnackbar('Password field must not be empty', 'Dismiss');
   }
  this.loginApi();
  }

  loginApi(){
    let payload = {};
    payload['mobile'] = this.mobile;
    payload['password'] = this.password; 
    payload['loginType'] = this.roleConfig.ROLE_AUDIT_HELP_DESK;
    this.authService.login(payload, this.loginResHandler);
  }

  loginResHandler = (res: any):void => {
    if(res && res.success) {
      this.openSnackbar('Login successful', 'Dismiss');
      this.router.navigate(['/audit-helpdesk']);
      return;
   }
   this.openSnackbar('Invalid credentials', 'Dismiss');
  }

  showPassword(){
    this.passwordVisibility = 'password' 
  }

  hidePassword(){
    this.passwordVisibility = 'text';
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }

  ngOnDestroy() {
    
  }
}
