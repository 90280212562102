import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot, UrlTree } from '@angular/router';
import { HomeComponent } from "src/app/home/home.component";
import { AlertDialogService } from "src/app/shared/alert/alert-dialog.service";
import { MessageConstantService } from "src/app/shared/services/messageconstant/message-constant.service";
import { StrorageService } from "./strorage.service";

@Injectable()
export class AuthGuardService implements CanActivate {
 
    constructor(private router:Router, private storageService: StrorageService,
        private alertDialogService: AlertDialogService, private messageConstantService: MessageConstantService ) {
    }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        if (this.storageService.isLoggedInTokenExpired()) {
            this.goBackHome();
            return false;
        } 
        return true;
    }

    goBackHome = () => {
        this.storageService.clear();
        this.alertDialogService.openDialog(this.messageConstantService.SESSION_EXPIRY_MESSAGE, () => {
            this.router.navigate(['/']);
        });
    }
}