import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageConstantService {

  public SESSION_EXPIRY_MESSAGE = "Session expired, login again";

  constructor() { }
}
