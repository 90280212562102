import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginButtonComponent } from '../login-button/login-button.component';
import { ProfileInfoModalComponent } from './profile-info-modal/profile-info-modal.component';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {

  @Input() name: string; 
  @Input() imagePath: string;
  @Input() content: string; 

  isReadMore = false;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog() {
      let data = {};
      data['title'] = this.name;
      data['content'] = this.content
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '50vh';
    dialogConfig.width = '32vw';
    dialogConfig.data = data;
    const dialogRef = this.dialog.open(ProfileInfoModalComponent,dialogConfig);

  }

  onReadMoreClick(){
    this.isReadMore = !this.isReadMore;
  }

}
