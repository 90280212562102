import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import * as Highcharts from 'highcharts';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-audit-helpdesk-chart',
  templateUrl: './audit-helpdesk-chart.component.html',
  styleUrls: ['./audit-helpdesk-chart.component.scss']
})
export class AuditHelpdeskChartComponent implements OnInit {

  public fromDate;

  public toDate;

  highcharts = Highcharts;

  chart;
  
  chartCallback;

  updateFromInput = false;

  maxDate = new Date();

  Days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  title = 'Population (in millions)';
  type = 'LineChart';
  data = [
   //   ["2012", 900, 390],
   //   ["2013", 1000, 400],
   //   ["2014", 1170, 440],
   //   ["2015", 1250, 480],
   //   ["2016", 1530, 540]
  ];
  columnNames = ['Date','Somashettihalli Panchayat', 'Kasaghattapura Panchayat','Vaderahalli Panchayat', 'Singanayakanahalli Panchayat'];
  options = {    
     title: 'Audit Helpdesk Statistics',
     pointSize:5,
     hAxis: {
      title: 'Days',
     ticks: [50,100,150,200] ,
      textStyle: {
       color: '#283745',
       fontSize: 15,
       fontName: 'Arial',
       bold: false,
       italic: false
    },    
    titleTextStyle: {
       color: '#888887',
       fontSize: 18,
       fontName: 'Arial',
       bold: false,
       italic: false
    }
   },
   vAxis:{
      title: 'Application Numbers',
      textStyle: {
       color: '#283745',
       fontSize: 15,
       fontName: 'Arial',
       bold: false,
       italic: false
    },    
    titleTextStyle: {
       color: '#888887',
       fontSize: 18,
       fontName: 'Arial',
       bold: false,
       italic: false
    }
   },
   titleTextStyle: {
      color: '#111111',
      fontSize: 23,
      marginBottom: 20,
      bold: true,
      textAlign: 'center'
  },
  legend:{textStyle:{fontSize:11},position: 'bottom'},

   
     tooltip: {
      isHtml : true
     }  ,
     colors: ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12']
  };
  width = 1200;
  height = 400;

  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C'];

  constructor(private applicationService: ApplicationService, private dateAdapter: DateAdapter<Date>) { 
   this.dateAdapter.setLocale('en-GB');
  }
  

  ngOnInit(): void {
  
   this.fetchApplicantData();
  
  }

  fetchApplicantData(){
     this.applicationService.getAuditHelpdeskStatistics().subscribe(res => {    
       this.data = this.parseResponseData(res.data);
     })
  }

  fetchApplicantDataByDateFilter(){
    let fromDate = `${this.fromDate.getFullYear()}-${this.fromDate.getMonth()+1 > 9 ? this.fromDate.getMonth()+1 : '0' + (this.fromDate.getMonth()+1)}-${this.fromDate.getDate() > 9 ? this.fromDate.getDate() : '0' + this.fromDate.getDate()}`;
    let toDate = `${this.toDate.getFullYear()}-${this.toDate.getMonth()+1 > 9 ? this.toDate.getMonth()+1 : '0' + (this.toDate.getMonth()+1)}-${this.toDate.getDate() > 9 ? this.toDate.getDate() : '0' + this.toDate.getDate()}`
    this.applicationService.getAuditHelpdeskStatisticsByDate(fromDate, toDate).subscribe(res => {
      if(res.data.length <= 0){
        return this.data = [];
      }
       this.data = this.parseResponseData(res.data);
    })
 }
  parseResponseData(res: any): any[] {
    let response = [];
     res.map((row, index)=>{
       let isUpdated = false;
       response.map((resRow, i)=>{
          if(row.issuedDate == resRow[0]){
            resRow[row.panchayat.id] = row.issuedTokens; 
            isUpdated = true;   
          }
       })
        if(isUpdated){
          return;
        }
       let rowData = [];
       rowData.push(row.issuedDate)
       rowData[1]=null;
       rowData[2]=null;
       rowData[3]=null;
       rowData[4]=null;
       rowData[row.panchayat.id] = row.issuedTokens;  
       response.push(rowData);
     })
    
     return this.sortData(response); 
  }


  sortData(data){
   data.sort((a, b) => {
      let date1 = new Date(a[0]);
      let date2 = new Date(b[0]);
  
      if (date1 < date2) {
          return -1;
      }
      if (date1 > date2) {
          return 1;
      }
      return 0;
  });
  data.map((row, index)=>{
   if(index == 0){
     row[1] = row[1] ? row[1] : 0;
     row[2] = row[2] ? row[2] : 0;
     row[3] = row[3] ? row[3] : 0;
     row[4] = row[4] ? row[4] : 0;
   }
   row[0] = this.parseDate(row[0])
})
  return data;
  }

  parseDate(dateString){
     let dateObj = new Date(dateString);
  let dateArr = dateString.split('-');
//   return dateObj;
     return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
  }

  fetchDay(dateString){
   let date = new Date(dateString);
   return date.getDay();
  }

  
   onFromDateChange(event){
     if(!this.fromDate || !this.toDate){
       return;
     }
    
     this.fetchApplicantDataByDateFilter();
   }

   onToDateChange(event){
    if(!this.fromDate || !this.toDate){
      return;
    }
    this.fetchApplicantDataByDateFilter();
  }

}
