import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl} from '@angular/forms';
import { EnclosureService } from 'src/services/enclosure-service/enclosure.service';
@Component({
  selector: 'app-print-dialog-acknowledgement',
  templateUrl: './print-dialog-acknowledgement.component.html',
  styleUrls: ['./print-dialog-acknowledgement.component.scss']
})
export class PrintDialogAcknowledgementComponent implements OnInit {
  autoCompleteCaseCtrl = new FormControl();
  public enclosures: any = [];
  constructor(private enclosureService:EnclosureService) { }

  ngOnInit(): void {
    this.init();
  }
   init = () => {
    this.enclosures= this.enclosureService.enclosures;    
   }
}
