import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrutinyAdminLoginComponent } from './scrutiny-admin-login/scrutiny-admin-login.component';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { ScrutinyAdminDashboardComponent } from './scrutiny-admin-dashboard/scrutiny-admin-dashboard.component';

const sharedComponents = [ScrutinyAdminLoginComponent, ScrutinyAdminDashboardComponent];

@NgModule({
  declarations: [...sharedComponents],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    CommonModule,
    SharedModule,
    MaterialModule,
    FormsModule
  ],
  exports: [...sharedComponents]
})

export class ScrutinyAdminModule { }
