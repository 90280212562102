import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { ScrutinyLoginComponent } from './scrutiny-login/scrutiny-login.component';
import { ScrutinyPreviewComponent } from './scrutiny-preview/scrutiny-preview.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ScrutinyDashboardComponent } from './scrutiny-dashboard/scrutiny-dashboard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommentDialogComponent } from './scrutiny-preview/comment-dialog/comment-dialog.component';
import { CommentDialogService } from './scrutiny-preview/comment-dialog/services/comment-dialog.service';
import { ActivityLogViewComponent } from './activity-log-view/activity-log-view.component';

const sharedComponents = [ActivityLogComponent, ScrutinyLoginComponent, 
  ScrutinyPreviewComponent, ScrutinyDashboardComponent, CommentDialogComponent];

@NgModule({
  declarations: [...sharedComponents, ActivityLogViewComponent],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    CommonModule,
    SharedModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule
  ],
  providers: [CommentDialogService],
  entryComponents: [...sharedComponents],
  exports: [...sharedComponents]
})
export class ScrutinyUserModule { }
