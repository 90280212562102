import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-conversion-of-order',
  templateUrl: './conversion-of-order.component.html',
  styleUrls: ['./conversion-of-order.component.scss']
})
export class ConversionOfOrderComponent implements OnInit {

  @Input() data : any;

  @Input() isEditable : any;

  public dcAprovalNumber : string;

  public dcAprovalDate : Date;
  
  public maxDate = new Date().getTime();

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.dcAprovalNumber = null;
    this.dcAprovalDate = null;
  }

  getPayload(){
    let payload = {};
    payload['dcAprovalNumber'] = this.dcAprovalNumber;
    payload['dcAprovalDate'] = this.dcAprovalDate;
    return payload;
   }

   ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.dcAprovalNumber = this.data.dcAprovalNumber;
    this.dcAprovalDate = this.data.dcAprovalDate;
   }

}
