import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-field-visit-activity-log-view',
  templateUrl: './field-visit-activity-log-view.component.html',
  styleUrls: ['./field-visit-activity-log-view.component.scss']
})
export class FieldVisitActivityLogViewComponent implements OnInit {

  public application_id : any;
  applicationData;
  selectedPanchayat = '';
  message:string;
  comment:string;
  status:string;
  
  public isLoading=false;
  selectedVillages: any;
  public commentTypes: any = [];
  public fieldVisitStatusList: any = [];
    public title: any;
    approved_filter: any;
    public disable:boolean=true;
  createdAt: any;
  date: any;
  refreshDataTable: any;
  currentPage: any;
  pageSize: any;
  fieldVisitData: any;
  fieldVisitDetails: any=[];
   
    
    constructor(private applicationService: ApplicationService,private route: ActivatedRoute, 
      private _snackBar: MatSnackBar, private router: Router) { 
        this.application_id = this.route.snapshot.queryParams['application_id'];
        console.log("jcc", this.application_id)
    }
  
    ngOnInit(): void {
      this.getFieldVisitActivityLogDetails();
    }
  
   getFieldVisitActivityLogDetails(){
     this.applicationService.getFieldVisitActivityLogDetails(this.application_id).subscribe(res=>{
        this.fieldVisitData = res;
        this.fieldVisitDetails=res.fieldVisitStatusList;
        this.applicationData = res.applicant;
        console.log(this.fieldVisitData);
        this.fetchAllCommentTypes(res.applicant.scrutinyUserCommentTypes);
        this.fetchAllFieldVisitStatusList(res.fieldVisitStatusList);
     })
   }
   private fetchAllCommentTypes(selectedCommentTypes: any){
    this.applicationService.getScrutinyUserCommentTypeRef().subscribe(response=>{
      let res = response.data;
      for(let i = 0;i < res.length;i++) {
        res[i].checked = false;
        for(let idx = 0;idx < selectedCommentTypes.length;idx++) {
          if(selectedCommentTypes[idx]['messageId'] == res[i]['messageId']) {
            res[i].checked = true;
            break;
          }
        }
        this.commentTypes.push(res[i]);
      }
    })
  }
  private fetchAllFieldVisitStatusList(selectedFieldVisitStatus: any){
    this.applicationService.getFieldVisitStatus().subscribe(res=> {
      for(let i = 0;i < res.length;i++) {
        res[i].checked = false;
        for(let idx = 0;idx < selectedFieldVisitStatus.length;idx++) {
          if(selectedFieldVisitStatus[idx]['statusId'] == res[i]['statusId']) {
            res[i].checked = true;
            break;
          }
        }
        this.fieldVisitStatusList.push(res[i]);
      }
    })
  }
  
    clickBack(){
      window.history.back();
    }

}
