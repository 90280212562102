<div class="rowcontent-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="contact"></app-top-bar>
        <app-header></app-header>

        
        
        <div class="row">
            <div class="col-sm-6 offset-sm-1">
          <div class="header"> Contact Us </div>  
<div class="row">
    <address class="address">
        The Committee<br>
        Bangalore Development Authority precincts<br>
        5th Main Rd, Kumarapark West,<br>
        Guttahalli, Bengaluru,<br>
        Karnataka 560020<br> 
        Ph: 9986******<br>
        </address> 
</div>
</div>
<div class="col-sm-4">
    <div class="row contact-text">For Assistance, Please reach out to -</div> 
    <div class="row text-email">bdasklhelpdesk@gmail.com</div>
</div>

        </div>
        <agm-map [zoom]="zoomLevel" [latitude]="lat" [longitude]="lng">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
        <app-footer></app-footer>
    </div>
    </div>
