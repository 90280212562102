import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { CommentDialogComponent } from '../comment-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CommentDialogService {

  dialogRef: MatDialogRef<CommentDialogComponent>;

  constructor(private dialog: MatDialog) {}

  openDialog(options: any): void {
      this.dialogRef = this.dialog.open(CommentDialogComponent, {
        data: {
            title: options.title,
            message: options.message,
            cancelText: options.cancelText,
            confirmText: options.confirmText
        },
        maxHeight: "100%",
        width: "540px",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true
      });
  }
  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
        return res;
      }
    ));
  }
}
