import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/services/user-service/user.service';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-scrutiny-admin-dashboard',
  templateUrl: './scrutiny-admin-dashboard.component.html',
  styleUrls: ['./scrutiny-admin-dashboard.component.scss']
})
export class ScrutinyAdminDashboardComponent implements OnInit {

  public dataSource = new MatTableDataSource();
  public panchayats : any[] = [];

  public villages : any[] = [];

  public isLoading = false;

  public selectedPanchayat;
  public isScrutinyUser;

  public selectVillage;

  public userName = "Username";

  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#E74C3C'];

  public isCommitteeUser;

  public faSync = faSync;

  public isRefreshing = false;


  constructor(private router: Router, public dialog: MatDialog, private userService: UserService,
    private applicationService: ApplicationService) { }

  ngOnInit(): void {
    let userType = this.userService.getUserType();
    if(userType != this.userService.COMITTEE_USER && userType != this.userService.SCRUTINY_USER){
        this.router.navigate(['/'])
    }
    this.userName = this.userService.getUserName();
    this.isCommitteeUser = userType == this.userService.COMITTEE_USER;
    if(this.isCommitteeUser)
    this.fetchAllPanchayats();
  }
  fetchAllPanchayats() {
    this.applicationService.fetchAllPanchayats().subscribe(res =>{
        this.panchayats = res;
        console.log(res);
    })
 }
 onPanchayatChange(id){
  this.fetchVillagesByPanchayat(id);
  this.selectedPanchayat = id;
}

onVillageChange(id){
  this.fetchApplicantsByVillage(id);
  this.selectVillage = id;
}
fetchVillagesByPanchayat(id){
  this.isLoading = true;
  this.applicationService.fetchAllVillagesByPanchayat(id).subscribe(res =>{
    this.villages = res;
    this.isLoading = false;
 })
}
private fetchApplicantsByVillage(id){
  if(this.isCommitteeUser){
    return;
  }
  this.isLoading = true;
  this.applicationService.fetchAllApplicantByVillage(id).subscribe(res =>{
    this.dataSource.data = res;
    this.isLoading = false;
  })
}



}
