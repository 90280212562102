import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-field-visit-user-activity-log',
  templateUrl: './field-visit-user-activity-log.component.html',
  styleUrls: ['./field-visit-user-activity-log.component.scss']
})
export class FieldVisitUserActivityLogComponent implements OnInit {

  public apiURL: string;

  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;

  public displayedColumns: string[] = ['sn','applicationNo', 'applicantName', 'comment'];
  
  public dataSource = new MatTableDataSource<any>();
  user: any;
  village: any;
  villageId: any;
  
  constructor(private applicationService: ApplicationService, 
    private router: Router,private storageService:StrorageService) {
      
  }

  ngOnInit(): void {
    this.user=this.storageService.getUser();
    }
  ngAfterViewInit() {
    this.fetchApplicantList();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.fetchApplicantList();
  }

  public fetchApplicantList = () => {
    this.applicationService.getFieldVisitActivityLog(this.currentPage,this.pageSize).subscribe(res => {
      this.refreshDataTable(res);
      console.log(res);
      
    })
  }

  private refreshDataTable = (res: any) => {
    this.totalSize = res.totalRecord;
    this.dataSource.data = res.data;
    this.dataSource._updateChangeSubscription();
  }

  clickBack(){
    window.history.back();

  }
  onPreviewClick(applicantId: any){
    this.router.navigate(['/field-visit-activity-log-view'], {queryParams:{application_id:applicantId}});
   }

  
}
