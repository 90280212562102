
<div class="container">
        <div class="col-sm-11 title">
            Enclosures
          </div>
        <!-- <div class="col-md-12"> -->
            <!-- data table -->
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    
                <!-- Position Column -->
                <ng-container matColumnDef="documentName">
                    <th mat-header-cell *matHeaderCellDef> Document Name</th>
                    <td mat-cell *matCellDef="let element" > {{element.documentName}} </td>
                </ng-container>
                
                <!-- Name Column -->
                <ng-container matColumnDef="link">
                    <th mat-header-cell *matHeaderCellDef> Link </th>
                    <td mat-cell *matCellDef="let element">
                        <a [hidden]="element.link == null" href="{{element.link}}" target="_blank">View</a>
                    <div [hidden]="element.link != null">No data available!</div></td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        <!-- </div> -->
        <!-- <app-footer></app-footer> -->
    </div>