import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/services/user-service/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationService } from 'src/services/application.service';
import { AuthService } from 'src/services/auth.service';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';
@Component({
  selector: 'app-scrutiny-admin-login',
  templateUrl: './scrutiny-admin-login.component.html',
  styleUrls: ['./scrutiny-admin-login.component.scss']
})
export class ScrutinyAdminLoginComponent implements OnInit {
  panchayats: any[];
  selectedPanchayat : number;
  password : string;
  mobileNo : number;

  faEye = faEye;

  faEyeSlash = faEyeSlash;

  passwordVisibility = 'password';

  

  constructor(private applicationService: ApplicationService, private authService: AuthService,
    private _snackBar: MatSnackBar, private router: Router, private userService: UserService, 
    private roleConfig: RoleConfigService) { }

  ngOnInit(): void {
    this.fetchPanchayats();
  }
  fetchPanchayats(){
    this.applicationService.fetchAllPanchayats().subscribe(res =>{
       this.panchayats = res;
    })
  }

  login(){
     if(!this.isValidLogin()){
       return;
     }
     let payload = {};
     payload['mobile'] = this.mobileNo;
     payload['password'] = this.password;
     payload['loginType'] = this.roleConfig.ROLE_SCRUTINY_ADMIN;
     this.authService.login(payload, this.loginResHandler);
  }

  loginResHandler = (res: any): void => {
    if(res && res.success) {
      this.openSnackbar('Login successful', 'Dismiss');
      this.router.navigate(['/scrutiny-admin-list']);
      return;
   }
   this.openSnackbar('Invalid credentials', 'Dismiss');
  }

  isValidLogin() : boolean{
    if(!this.mobileNo){
      this.openSnackbar('Please enter mobile number', 'Dismiss');
      return false;
   }
  
    if(!this.password){
      this.openSnackbar('Please enter password', 'Dismiss');
      return false;
   }
  return true;
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }

  showPassword(){
    this.passwordVisibility = 'password'
  }

  hidePassword(){
    this.passwordVisibility = 'text';
  }

}


