import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-profile-info-modal',
  templateUrl: './profile-info-modal.component.html',
  styleUrls: ['./profile-info-modal.component.scss']
})
export class ProfileInfoModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ProfileInfoModalComponent>,  @Inject(MAT_DIALOG_DATA, ) public data,) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }
  
}
