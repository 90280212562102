<div class="content-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
        
    </div>

     <div class="row tool-bar">
         <div >
            <div class="refresh-btn"> <button mat-button class="btn btn-primary" (click)="showActivityLog()">Activity Log</button></div>
                </div> 
                <div class="col-sm-2 offset-sm-1 user-details">
                    <div class="key-text">
                        <br>
                        {{village.name}}
                   </div>
                    </div>
                <div class="col-sm-2 offset-sm-1 user-details">
                    <div class="value-text">
                        Welcome <br>
                        {{user['firstName']}}!
                    </div>
                    </div>
 </div>
 <div fxLayout="row" fxLayoutAlign="center stretch"  >
    <!-- data table -->
    <mat-card>
 
 <table mat-table [dataSource]="dataSource">
     <ng-container matColumnDef="sn">
         <th mat-header-cell *matHeaderCellDef> Serial No. </th>
         <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}</td>
     </ng-container>
     <ng-container matColumnDef="applicationNo">
         <th mat-header-cell *matHeaderCellDef> Application No. </th>
         <td mat-cell *matCellDef="let element" class="column-container">
         <a (click)="onPreviewClick(element.id)" class="preview-link">{{element.applicationNo}}</a>
         </td>
     </ng-container>
     <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element" class="column-container">{{element.scrutinyUserStatus}}
        </td>
    </ng-container>
     
     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
     <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
 </table>
 <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
 <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
     [pageIndex]="currentPage" (page)="getApplicantPaginatorData($event)">
 </mat-paginator>
</mat-card>
</div>
<app-footer class="col-md-10 offset-sm-3" ></app-footer>
 </div>
 