<div class="row content-area">
    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Occupancy Certificate No / ವಾಸ ದೃಢೀಕರಣ  ಪ್ರಮಾಣಪತ್ರ ಸಂಖ್ಯೆ. :</label>
            <input type="text" [(ngModel)]="occupancyNo" class="form-textbox" [readonly] = "(data && data.occupancyNumber) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Date / ದಿನಾಂಕ :</label>
            <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="occupancyDate" class="form-textbox" [readonly] = "(data && data.occupancyDate) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Issuing Authority / ನೀಡಲಾದ ಕಛೇರಿ :</label>
            <input type="text" [(ngModel)]="issuingAuthority" class="form-textbox" [readonly] = "(data && data.occupancyAuth) && !isEditable"/>
        </div>
    </div>
</div>

