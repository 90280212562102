import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {

  public comment: string;
  constructor(private dialogRef: MatDialogRef<CommentDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: { message: string }) { }

  ngOnInit(): void {

  }

  public onOkClicked = () => {
    if(!this.comment) {
      alert("please enter comment");
      return;
    }
    let data = {};
    data['confirmed'] = true;
    data['comment'] = this.comment;
    this.dialogRef.close(data);
  }

  public onCancelClicked = () => {
    this.onCanceled();
  }

  @HostListener("keydown.esc") 
  public onEsc() {
    this.onCanceled();
  }

  onCanceled(): void {
    let data = {};
    data['confirmed'] = false;
    this.dialogRef.close(data);
  }
}
