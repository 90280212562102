import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user-service/user.service';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { RoleConfigService } from '../shared/config/role/role.config.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {

  password : string;
  mobileNo : number;
  passwordVisibility = 'password';
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  showOtpScreen = false;
  isLoading = false;
  resendEnabled = false;
  otpResendTime = 120;
  timer: any;

  constructor(private authService: AuthService,
     private _snackBar: MatSnackBar, private router: Router, private userService: UserService, 
     private roleConfig: RoleConfigService) { 
     
     }

  ngOnInit(): void {
  }
  
  sendOtp(){
    if(!this.mobileNo){
      this.openSnackbar('Mobile number must not be empty', 'Dismiss');
      return;
    }
    this.isLoading = true;
    let payload = {};
    payload['mobile'] = this.mobileNo;
      this.authService.adminUserGenerateOTP(payload).subscribe(res =>{
          this.isLoading = false;
          this.startResendOTPTimer();
          if(res?.success == true){
           return this.showOtpScreen = true;
          }
          return this.openSnackbar("Not a Registered number", "Dismiss");
      })
  }

  startResendOTPTimer(){
    if(this.timer){
       clearInterval(this.timer)
    }
    this.resendEnabled = false;
    this.otpResendTime = 120;
    this.timer = setInterval(()=>{
       if(this.otpResendTime <= 0){
           clearInterval(this.timer);
       }
       this.otpResendTime -= 1;
    }, 1000)
    setTimeout(()=>{
       this.resendEnabled = true;
    },this.otpResendTime*1000);
  }

  convertToTimeFormat(timeString){
    return `${Math.trunc(timeString / 60) > 9 ? Math.trunc(timeString / 60) : '0'+ Math.trunc(timeString / 60)}:${timeString % 60 > 9 ? timeString % 60 : '0'+timeString % 60  }`
  }

  changeNumber(){
    this.password=' ';
    this.showOtpScreen = false;
  }

  resendOTP(){
    this.sendOtp();
  }

  login(){
    if(!this.isValidLogin()){
      return;
    }
    let payload = {};
    payload['mobile'] = this.mobileNo;
    payload['password'] = this.password;
    payload['loginType'] = this.roleConfig.ROLE_ADMIN_USER;
    this.authService.login(payload, this.loginResHandler);
  }

  ngOnDestroy() {

  }

  loginResHandler = (res: any):void => {
    if(res && res.success) {
      this.openSnackbar('Login successful', 'Dismiss');
      this.router.navigate(['/admin']);
      return;
   }
   this.openSnackbar('Invalid credentials', 'Dismiss');
  }

  isValidLogin() : boolean{
      if(!this.mobileNo){
        this.openSnackbar('Please enter mobile number', 'Dismiss');
        return false;
     }
      if(!this.password){
        this.openSnackbar('Please enter password', 'Dismiss');
        return false;
     }
    return true;
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }
  showPassword(){
   this.passwordVisibility = 'text';
 }

 hidePassword(){
   this.passwordVisibility = 'password'
 }

}
