import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user-service/user.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';

@Component({
  selector: 'app-community-login',
  templateUrl: './community-login.component.html',
  styleUrls: ['./community-login.component.scss']
})
export class CommunityLoginComponent implements OnInit {

  // panchayats : any[];
  // selectedPanchayat : number;
  password : string;
  mobileNo : number;
  passwordFieldType = 'password';
  showOtpScreen = false;
  isLoading = false;
  resendEnabled = false;
  otpResendTime = 120;
  faEye = faEye;

  faEyeSlash = faEyeSlash;

  passwordVisibility = 'password';

  constructor(private applicationService: ApplicationService, private authService: AuthService,
     private _snackBar: MatSnackBar, private router: Router, private userService: UserService, 
     private roleConfig: RoleConfigService) { }

  ngOnInit(): void {
    // this.fetchPanchayats();
  }


  sendOtp(){
    if(!this.mobileNo){
      this.openSnackbar('Mobile number must not be empty', 'Dismiss');
      return;
    }
    this.isLoading = true;
    let payload = {};
    payload['mobile'] = this.mobileNo;
      this.authService.committeeUserGenerateOTP(payload).subscribe(res =>{
          this.isLoading = false;
          this.startResendOTPTimer();
          if(res?.success == true){
           return this.showOtpScreen = true;
          }
          return this.showOtpScreen = true;
      })
  }

  startResendOTPTimer(){
    this.resendEnabled = false;
    this.otpResendTime = 120;
    let timer = setInterval(()=>{
       if(this.otpResendTime <= 0){
           clearInterval(timer);
       }
       this.otpResendTime -= 1;
    }, 1000)
    setTimeout(()=>{
       this.resendEnabled = true;
    },this.otpResendTime*1000);
  }

  convertToTimeFormat(timeString){
    return `${Math.trunc(timeString / 60) > 9 ? Math.trunc(timeString / 60) : '0'+ Math.trunc(timeString / 60)}:${timeString % 60 > 9 ? timeString % 60 : '0'+timeString % 60  }`
  }

  changeNumber(){
    this.showOtpScreen = false;
  }

  resendOTP(){
    this.sendOtp();
  }

  login(){
     if(!this.isValidLogin()){
       return;
     }
      let payload = {};
      payload['mobile'] = this.mobileNo;
      payload['password'] = this.password;
      payload['loginType'] = this.roleConfig.ROLE_LEADERSHIP_USER;
      this.authService.login(payload, this.loginSuccess);
  }

  loginSuccess = (res: any): void => {
    if(res && res.success) {
      this.router.navigate(['/applicant-list']);
      
   }else{
   this.openSnackbar('Invalid credentials', 'Dismiss');
   }
   return;
  }

  changePasswordVisibility($event){
     if($event.target.checked){
        return this.passwordFieldType = 'text';
     }  
     return this.passwordFieldType = 'password';
  }

  isValidLogin() : boolean{
      if(!this.mobileNo){
        this.openSnackbar('Please enter mobile number', 'Dismiss');
        return false;
     }
      if(!this.password){
        this.openSnackbar('Please enter password', 'Dismiss');
        return false;
     }
    return true;
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }
  showPassword(){
    this.passwordVisibility = 'password'
  }

  hidePassword(){
    this.passwordVisibility = 'text';
  }

  ngOnDestroy() {
    
  }

}
