<div class="side-menu-container">
    <div *ngFor="let subTitle of menus">
        <div class="form-subtitle" (click)="openNextTab(subTitle, menus)">
            <span class="iconify arrow-icon" [class.selected-icon]="subTitle.active" [class.icon]="!subTitle.active" 
            data-icon="ion-md-arrow-dropright" data-inline="false" *ngIf="subTitle.type == 'child'"></span>
            <span [class.selected-sub-field]="subTitle.active"
            [class.sub-field]="!subTitle.active"> {{subTitle.name}} </span>
        </div>
        <div class="form-subtitle" *ngFor="let subMenu of subTitle.sub_menus" (click)="openNextTab(subMenu, subTitle.sub_menus)">
                <span class="iconify arrow-icon" [class.selected-icon]="subMenu.active" [class.icon]="!subMenu.active" 
                data-icon="ion-md-arrow-dropright" data-inline="false" *ngIf="subMenu.type == 'child'"></span>
                <span [class.selected-sub-field]="subMenu.active"
                [class.sub-field]="!subMenu.active"> {{subMenu.name}} </span> 
        </div>
    </div>
</div>
