<div class="row content-area">
    <div class="col-sm-10 offset-sm-1">
  <app-top-bar selectedTab="home"></app-top-bar>
  <app-header></app-header>
  <div class="title">
      Welcome to JCC-SKL Admin Login
  </div>
  <div class="col-sm-6 offset-sm-3">
  <mat-card>
   


        <!-- <div class="col-sm-8 offset-sm-2">
            <div class="row application-form-row password-field">
                <label class="form-label">Enter Password : </label>
                <input [type]="passwordVisibility" [(ngModel)]="password" class="form-textbox"/>
                <fa-icon [hidden]="passwordVisibility == 'text'" [icon]="faEye" (click)="showPassword()" class="field-icon" ></fa-icon>
                <fa-icon [hidden]="passwordVisibility == 'password'" [icon]="faEyeSlash" (click)="hidePassword()" class="field-icon" ></fa-icon>
            </div>
        </div>

        <div class="col-sm-8 offset-sm-2">
                <div class="row application-form-row ">
                    <label class="form-label">Enter Registered Mobile Number: </label>
                    <input type="number" [(ngModel)]="mobileNo" class="form-textbox"/>
                </div>
            </div>
    
            <div class="row">
                <div class="col-sm-6 offset-sm-3">
                    <button class="btn btn-primary login-btn" (click)="login()">LOGIN</button>
                </div>
            </div> -->

            <div [hidden]="showOtpScreen">
                <div class="col-sm-8 offset-sm-2">
                    <div class="row application-form-row ">
                        <label class="form-label">Enter Registered Mobile Number : </label>
                        <input type="number" [(ngModel)]="mobileNo" class="form-textbox"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 offset-sm-3">
                        <button class="btn btn-primary login-btn send-otp-btn" (click)="sendOtp()">SEND OTP</button>
                    </div>
                </div>
               </div>
               <div [hidden]="!showOtpScreen">
                <div class="col-sm-8 offset-sm-2">
                    <div class="row application-form-row ">
                        <label class="form-label">Enter OTP: </label>
                        <input type="number" [(ngModel)]="password" class="form-textbox"/>
                    </div>
                </div>
                <div class="col-sm-8 offset-sm-2">
                    <div class="label-btn label-pointer" (click)="changeNumber()">Change Number</div>
                    <div class="label-btn label-btn-disabled" [hidden]="resendEnabled">Resend OTP in {{convertToTimeFormat(otpResendTime)}}</div>
                    <div class="label-btn label-pointer" [hidden]="!resendEnabled" (click)="resendOTP()">Resend OTP</div>
                </div>
        
                <div class="row">
                    <div class="col-sm-6 offset-sm-3">
                        <button class="btn btn-primary login-btn submit-otp-btn" (click)="login()">SUBMIT</button>
                    </div>
                </div>
               </div>
   

  </mat-card>
</div>
  <app-footer></app-footer>
    </div>
    </div>

