import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JccnoEncodeDecodeService {

  constructor() { }

  public encode(value){
    return btoa(value);
  }

  public decode(value){
    return atob(value);
  }

}
