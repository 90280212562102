<div class="row content-area">
    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Financier/Financial Institution/Bank /  ಫೈನಾನ್ಷಿಯರ್ / ಹಣಕಾಸು ಸಂಸ್ಥೆ / ಬ್ಯಾಂಕ್ :</label>
            <input type="text" [(ngModel)]="bankName" class="form-textbox" [readonly] = "(data && data.bankName) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Amount sanctioned / ಮಂಜೂರು ಮಾಡಲಾದ ಮೊತ್ತ :</label>
            <input type="text" [(ngModel)]="amountSanctioned" class="form-textbox" [readonly] = "(data && data.vsanctionAmount) && !isEditable" />
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Duration of payment / ಪಾವತಿಯ ಅವಧಿ :</label>
            <input type="text" [(ngModel)]="paymentDuration" class="form-textbox" [readonly] = "(data && data.paymentDuration) && !isEditable"/>
        </div>
    </div>
    <div class="col-sm-12 col-md-12">
   <div class="row application-form-row">
    <label class="form-label">Applicant Type</label>
   </div>
    </div>
    <div class="col-sm-12 col-md-12">
        <div class="row radio-row">
            <input id="single" type="radio" [(ngModel)]="applicantType" value="single" name="applicantType" [disabled] = "(data && (data.singleApplication == 'Y'|| data.jointApplication == 'Y')) && !isEditable"/>
            <label for="single" class="radio-text">Single Applicant / ಏಕ ಅರ್ಜಿದಾರ</label>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row radio-row">
            <input id="joint" type="radio" [(ngModel)]="applicantType" value="joint" name="applicantType" [disabled] = "(data && (data.singleApplication == 'Y'|| data.jointApplication == 'Y')) && !isEditable"/>
            <label for="joint" class="radio-text">Joint Applicant / ಜಂಟಿ ಅರ್ಜಿದಾರ</label>
        </div>
    </div>

    <div class="title">Last loan paid receipt / ಇತ್ತೀಚಿನ ಸಾಲದ ಕಂತು ಪಾವತಿ ವಿವರ</div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Receipt No / ರಶೀದಿ ಸಂಖ್ಯೆ. :</label>
            <input type="text" [(ngModel)]="receiptNo" class="form-textbox" [readonly] = "(data && data.receiptNumber) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Date / ದಿನಾಂಕ :</label>
            <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="receiptDate" class="form-textbox" [readonly] = "(data && data.receiptDate) && !isEditable" />
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Amount / ಮೊತ್ತ :</label>
            <input type="text" [(ngModel)]="receiptAmount" class="form-textbox" [readonly] = "(data && data.receiptAmount) && !isEditable"/>
        </div>
    </div>
</div>

