import { DOCUMENT } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Input } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { ApplicantEnclosureListComponent } from '../applicant-enclosure-list/applicant-enclosure-list.component';
@Component({
  selector: 'app-preview-enclosure',
  templateUrl: './preview-enclosure.component.html',
  styleUrls: ['./preview-enclosure.component.scss']
})
export class PreviewEnclosureComponent implements OnInit {
  
  @Input() id;
  public displayedColumns: string[] = ['documentName', 'link'];

  public dataSource = new MatTableDataSource();

  public faCheck = faCheck;

  public faTimes = faTimes;

  public faTrash = faTrash;

  public files: any[];

  public isLoading = false;

  public data;

  public enclosure;

  public applicationId;

  public applicantName;
  propertyImage: string;
  propertyPicture: string;
  applicationData: any;
  

  constructor(private applicationService: ApplicationService, 
    private route:ActivatedRoute,
    private router: Router, private _snackBar: MatSnackBar) {
    this.files = [];
  
    this.applicationId=this.route.snapshot.queryParams['application_id'];
    console.log(this.applicationId)

   }

  ngOnInit(): void {
   this.fetchEnclosureList();
  }
 
  private fetchEnclosureList(): void {
    this.applicationService.getApplicantEnclosureList(this.applicationId).subscribe(res =>{
      const documents = [];
      for (const [key, value] of Object.entries(res)) {
        let document = {};
        document['documentName'] = key;
        document['link'] = value;
        documents.push(document);
      }
      this.dataSource.data = documents;
    })
  }
  
  cancelClick(){
    
  }

}
