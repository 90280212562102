import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private API_URL: string;

  constructor(private http: HttpClient, private _snackBar: MatSnackBar
    ) { 
    this.API_URL = `${environment.BASE_URL}` + 'api';
  }
    private formatErrors(error: any) {
   alert('Api Error' + error?.error?.message)
      return  throwError(error.error);
    }
  
    get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.API_URL + `${path}`, { params });
    }
  
    put(path: string, body: Object = {}): Observable<any> {
      return this.http.put(
        this.API_URL + `${path}`,body
      );
    }
  
    post(path: string, body: Object = {}): Observable<any> {
      return this.http.post(this.API_URL + `${path}`, body);
    }
  
    delete(path: string): Observable<any> {
      return this.http.delete(
        this.API_URL + `${path}`
      );
    }

    openSnackbar(message: string, action: string){
      this._snackBar.open(message, action);
    }
}
