import { Component, OnInit, Input } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { ApiService } from 'src/services/api.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-applicant-datatable',
  templateUrl: './applicant-datatable.component.html',
  styleUrls: ['./applicant-datatable.component.scss']
})
export class ApplicantDatatableComponent implements OnInit {

  public displayedColumns: string[] = ['applicationNo', 'applicantName','telephoneNumber','action'];

  dataSource = new MatTableDataSource();

  constructor() { }

  ngOnInit(): void {
    this.fetchApplicantList();
  }

  public fetchApplicantList = () => {
    
  }
}
