import { Injectable } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ConfirmDialogService {

  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(private dialog: MatDialog) {}

  openDialog(options: any): void {
      this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
            title: options.title,
            message: options.message,
            cancelText: options.cancelText,
            confirmText: options.confirmText
        },
        maxHeight: "100%",
        width: "540px",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true
      });
  }
  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
        return res;
      }
    ));
  }
}