import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-applicant-view',
  templateUrl: './applicant-view.component.html',
  styleUrls: ['./applicant-view.component.scss']
})
export class ApplicantViewComponent implements OnInit {
  @Input() data: any = {};
  constructor() { }

  ngOnInit(): void {
  }

  clickBack(){
    window.history.back();
  }

  encloPage() {
    let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    window.open(fullURL+'#/preview-enclosure?application_id='+this.data.id, '_blank');
  }

}
