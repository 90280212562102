<div (click)="clickOption()" class="content-area" [class.cursor-style]="type == 'panchayat' || !isCommitteeUser">
    <div class="name col-sm-12" [class.village]="type == 'village'" [class.panchayat]="type == 'panchayat'"  [style.background-color]=" data.id == selectedId ? '#ffffff' :color"
    [style.color]=" data.id != selectedId ? '#ffffff' :color" [style.border-color]=" data.id != selectedId ? '#ffffff' :color">
        <div>
            {{data.name}}
        </div>
        <div  [class.village-count]="type == 'village'" [class.panchayat-count]="type == 'panchayat'">
        (Applications - {{applicantCount}})
        </div>
        
    </div>
</div>
