<div class="col-sm-10 offset-sm-1">
  <app-top-bar selectedTab="home"></app-top-bar>
  <app-header></app-header>
  <h1 class="text-center title">Dr.Shivarama Karanth Layout – Revenue Sites {{heading}}</h1>
  <div class="col-sm-2 offset-sm-10 user-details">

      <div class="value-text">
          Welcome 
      </div>
      <div class="value-text">
           {{userName}} !
      </div>

    </div>

<div class="col-sm-4 offset-sm-4">
  <button class="btn btn-primary add-btn" (click)="openNewApplication()">Add new application</button>
  <button class="btn btn-primary add-btn" [hidden]="isExistingEnabled" (click)="setEnableExistance()">Select Existing Application</button>
  <form [formGroup]="myGroup" [hidden]="!isExistingEnabled">
      <mat-form-field class="example-full-width">
          <mat-label>Select Application Number</mat-label>
          <input type="text" matInput name="applicationId" formControlName="applicationId" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="selectOption(option)">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
  </form>
</div>
<app-footer></app-footer>
</div>