<div class="row content-area">
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row">
                    <label class="form-label">L. P No/ಎಲ್.ಪಿ. ಸಂಖ್ಯೆ :</label>
                    <input type="text" [(ngModel)]="gpAprovalNumber" class="form-textbox" [readonly] = "(data && data.gpAprovalNumber) && !isEditable"/>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row">
                    <label class="form-label">Date of Approval / ಅನುಮೋದನೆಯ ದಿನಾಂಕ :</label>
                    <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="gpAprovalDate" class="form-textbox" [readonly] = "(data && data.gpAprovalDate) && !isEditable"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row">
                    <label class="form-label">Name of the Panchayat / ಪಂಚಾಯತ್ ಹೆಸರು :</label>
                    <select [(ngModel)]="panchayat" class="form-textbox" [disabled]="data && data.panchayat" >
                        <option disabled>Select Panchayat</option>
                      <option  *ngFor="let panchayat of panchayats" [value]="panchayat.id">
                          {{panchayat.name}}
                      </option>
                    </select>
                </div>
            </div>
</div>
        


