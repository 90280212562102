<div fxFlex="100">
    <div fxFlex="25" class="side-menu-container">
        <app-side-menu [menus]="menus" (onMenuClick)="onItemClicked($event)"></app-side-menu>
    </div>
    <div fxFlex="75" style="padding-left: 55px">
        <app-construct-without-approval [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'constructed_with_approval'"></app-construct-without-approval>
        <app-bbd-approved [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'bda_approved_layout'"></app-bbd-approved>
        <app-approval-from-panchayat [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'panchayat_approval'"></app-approval-from-panchayat>
        <app-conversion-of-order [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'conversion_order_dc'"></app-conversion-of-order>
        <app-approval-of-bbmp [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'approval_bbmp'"></app-approval-of-bbmp>
        <app-commencement-certificate [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'commencement_certificate'"></app-commencement-certificate>
        <app-completion-certificate [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'completion_certificate'"> </app-completion-certificate>
        <app-occupancy-certificate [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'occupancy_certificate'"></app-occupancy-certificate>
        <app-electricity-connection [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'electricity_connection'"></app-electricity-connection>
        <app-water-connection [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'water_connection'"></app-water-connection>
        <app-details-of-loan-availed [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'loan_details_availed'"></app-details-of-loan-availed>
        <app-court-case-pending [isEditable]="isEditable" [data]="data" 
        [hidden]="selectedMenu['code'] != 'court_case_stay_order'"></app-court-case-pending>
        <div fxLayout="row" style="margin-top: 20px">
            <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()">Save & Continue</button>
        </div>
    </div>
</div>
        
