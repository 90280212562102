import { Injectable } from '@angular/core';
import { StrorageService } from '../strorage.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  LANGUAGE_KEY = "preferred-language";

  constructor(private storageService: StrorageService) { }

  setLanguage(language){
    return this.storageService.setItem(language, this.LANGUAGE_KEY);
  }


  getLanguage(){
    return this.storageService.getItem(this.LANGUAGE_KEY);
  }
}
