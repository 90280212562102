<div class="jumbotron" (click)="onStaticsClick()" [class.cursor-style]="!isCommitteeUser">
    <div class="TotalApplicant-header">
        Total Applications : {{applicantCount}}
    </div>
    &nbsp;&nbsp;&nbsp;&nbsp;
   <div class="row">
           <div class="col-sm-10 offset-sm-1">
            &nbsp;&nbsp;&nbsp;
           <div class="row">
       <div class="col-sm-3">
           <div class="percent-txt">{{onlineApplicantCount}}</div>
           <div class="percent">{{onlineApplicantPercent}} %</div>
           <div class="explain-txt">Applications received online</div>
       </div>
       <div class="col-sm-3">
        <div class="percent-txt">{{helpdeskApplicantCount}}</div>
        <div class="percent">{{helpdeskApplicantPercent}} %</div>
        <div class="explain-txt">Applications received through helpdesk</div>
    </div>
    <div class="col-sm-3">
        <div class="percent-txt">{{submitWithoutDocuments}}</div>
        <div class="percent">{{submitWithoutPercent}} %</div>
        <div class="explain-txt">Applications submitted without documents</div>
    </div>
    <div class="col-sm-3">
        <div class="percent-txt">{{readyToScrutinized}}</div>
        <div class="percent">{{readyToScrutinizedPercent}} %</div>
        <div class="explain-txt">Applications ready to be Scrutinized</div>
    </div>
</div>
</div>
   </div>
    </div>
    
