import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationService } from 'src/services/application.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-applicant-enclosure-list',
  templateUrl: './applicant-enclosure-list.component.html',
  styleUrls: ['./applicant-enclosure-list.component.scss']
})
export class ApplicantEnclosureListComponent implements OnInit {

  public displayedColumns: string[] = ['documentName', 'link'];

  public dataSource = new MatTableDataSource();
  id: any;
  public faTimes = faTimes;
  constructor(private route: ActivatedRoute, public dialogRef: MatDialogRef<ApplicantEnclosureListComponent>,  @Inject(MAT_DIALOG_DATA, ) public data,
    private applicationService: ApplicationService) {
      this.id = data;
    console.log("jcc", this.id)
     }

  ngOnInit(): void {
    // let applicantId = this.route.snapshot.queryParams["applicantId"];
    // console.log("applicationId");
    // console.log(applicantId);
    this.fetchEnclosureList(this.id);
  }

  private fetchEnclosureList(id: any): void {
    this.applicationService.getApplicantEnclosureList(this.id).subscribe(res =>{
      const documents = [];
      for (const [key, value] of Object.entries(res)) {
        let document = {};
        document['documentName'] = key;
        document['link'] = value;
        documents.push(document);
      }
      this.dataSource.data = documents;
    })
  }
  close() {
    this.dialogRef.close();
  }
  cancelClick(){
    this.close();
  }
}
