import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-details-of-loan-availed',
  templateUrl: './details-of-loan-availed.component.html',
  styleUrls: ['./details-of-loan-availed.component.scss']
})
export class DetailsOfLoanAvailedComponent implements OnInit {

  @Input() data : any;
  @Input() isEditable : any;

  public amountSanctioned : string;

  public bankName : string;

  public paymentDuration : string;

  public receiptNo : string;

  public receiptDate : Date;

  public receiptAmount : string;

  public applicantType : string;

  public maxDate = new Date().getTime();

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.amountSanctioned = null;
    this.bankName = null;
    this.paymentDuration = null;
    this.receiptNo = null;
    this.receiptDate = null;
    this.receiptAmount = null;
    this.applicantType = null;
  }

  getPayload(){
    console.log('Application type ', this.applicantType);
    let payload = {};
    payload['vsanctionAmount'] = this.amountSanctioned;
    payload['bankName'] = this.bankName;
    payload['paymentDuration'] = this.paymentDuration;
    payload['receiptNumber'] = this.receiptNo;
    payload['receiptDate'] = this.receiptDate;
    payload['receiptAmount'] = this.receiptAmount;
    payload['singleApplication'] = this.applicantType == 'single' ? 'Y':'N';
    payload['jointApplication'] = this.applicantType == 'joint' ? 'Y':'N';
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.amountSanctioned = this.data.vsanctionAmount;
    this.bankName = this.data.bankName;
    this.paymentDuration = this.data.paymentDuration;
    this.receiptNo = this.data.receiptNumber;
    this.receiptDate = this.data.receiptDate;
    this.receiptAmount = this.data.receiptAmount;
    this.applicantType = this.data.singleApplication == 'Y' ? 'single' : this.data.jointApplication == 'Y' ? 'joint' : null;
   }

}
