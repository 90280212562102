<div class="row content-area">

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row">
         <label class="form-label">Constructed with Approval</label>
        </div>
         </div>
         <div class="col-sm-12 col-md-12">
             <div class="row radio-row">
                 <input id="single" type="radio" [(ngModel)]="isConstructWithoutApproval" [disabled] = "(data && data.constNoAproval != null && data.constNoAproval != undefined) && !isEditable" value="yes" name="isConstructWithoutApproval"/>
                 <label for="single" class="radio-text">Yes / ಹೌದು</label>
             </div>
         </div>
         <div class="col-sm-12 col-md-12">
             <div class="row radio-row">
                 <input id="joint" type="radio" [(ngModel)]="isConstructWithoutApproval" [disabled] = "(data && data.constNoAproval != null && data.constNoAproval != undefined) && !isEditable" value="no" name="isConstructWithoutApproval"/>
                 <label for="joint" class="radio-text">No / ಇಲ್ಲ</label>
             </div>
         </div>
</div>