import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bbd-approved',
  templateUrl: './bbd-approved.component.html',
  styleUrls: ['./bbd-approved.component.scss']
})
export class BbdApprovedComponent implements OnInit {

  @Input() data : any;

  @Input() isEditable : any;

 public bdaLayoutAprovalNumber : string;
 public bdaLayoutAprovalDate : Date;
 public bdaAprovalNumber : string;
 public bdaAprovalDate : Date;
 public maxDate = new Date().getTime();

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.bdaLayoutAprovalNumber = null;
    this.bdaLayoutAprovalDate = null;
    this.bdaAprovalNumber = null;
    this.bdaAprovalDate = null;
  }

  getPayload(){
    let payload = {};
    payload['bdaLayoutAprovalNumber'] = this.bdaLayoutAprovalNumber;
    payload['bdaLayoutAprovalDate'] = this.bdaLayoutAprovalDate;
    payload['bdaAprovalNumber'] = this.bdaAprovalNumber;
    payload['bdaAprovalDate'] = this.bdaAprovalDate;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.bdaLayoutAprovalNumber = this.data.bdaLayoutAprovalNumber;
    this.bdaLayoutAprovalDate = this.data.bdaLayoutAprovalDate;
    this.bdaAprovalNumber = this.data.bdaAprovalNumber;
    this.bdaAprovalDate = this.data.bdaAprovalDate;
   }

}
