import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { ApiService } from './api.service';
import { UserService } from './user-service/user.service';
import { StrorageService } from './strorage.service';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private apiService: ApiService, private storageService: StrorageService, 
    private roleConfig: RoleConfigService) { }

  public login(payload: any, successCallback: any) : void {
    this.apiService.post('/v1/login', payload).subscribe(res => {
      this.storageService.saveUser(res.data);
      successCallback(res);
    });
  }

   public adminLogin(payload: any) : Observable<any> {
    payload['loginType'] = 'admin_user';
    return this.apiService.post('/v1/login', payload);
   }
  
   //otp generation
   public onlineApplicationGenerateOtp(payload: any) : Observable<any> {
    payload['loginType'] = 'online_application_user';
    return this.apiService.post('/v1/generate_email_otp', payload);
   }

  public committeeUserGenerateOTP(payload: any) : Observable<any> {
    payload['loginType'] = this.roleConfig.ROLE_COMMITTEE;
    return this.apiService.post('/v1/generate_sms_otp', payload);
  }
  
  public scrutinyUserGenerateOTP(payload :any)  : Observable<any> {
    payload['loginType'] = 'scrutiny_user';
    return this.apiService.post('/v1/generate_sms_otp',payload);
  }
  
  public adminUserGenerateOTP(payload: any) : Observable<any> {
    payload['loginType'] = 'admin_user';
    return this.apiService.post('/v1/generate_sms_otp', payload);
  }

   public fetchOnlineApplicantNo() : Observable<any> {
    return this.apiService.get('/v1/onlineApplicationNo');
   }

   public fetchApplicantNo(): Observable<any> {
    return this.apiService.get('/v1/applicationNo');
   }
   
}
