import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnclosureService {

  // USER_TYPE_CONSTANTS
  APPLICATION_FORM = "applicationFormCopy";
  ADHAR_CARD = "aadharCard";
  PANCARD= "panCard";
  PASSPORT= "passportPic";
  NOTORIZED_SELF_AFFIDAVIT = "notorizedSelfAffidavit";
  REGISTRATION_FEES = "regnFeeConsentAffidavit";
  SALE_DEED= "saleDeed";
  ENCUMBERANCE_CERTIFICATE = "encumberanceCert";
  LAYOUT_PLAN = "layout_plan";
  TAX_PAID_RECEIPT= "taxPaidReceipt";
  DOM_CERTIFICATE="domCert";
  REGN_FEEPAIS_CHALLAN = "regnFeepaidChallan";
  FORM_AFFIDAVIT= "formAAffidavit";
  COURTCASE_AFFIDAVT= "courtCaseAffidavit";
  OTHER_RELATED_DOCS="otherRelatedDocs";
  enclosures = [
    {
      "title": "1. ಪೂರ್ಣ ಅಂಚೆ ವಿಳಾಸ ನಮೂದಿಸಿರುವ ಅರ್ಜಿ/Prescribed Application form with Complete Address .",
      "id": "applicationFormCopy",
      "active": false
    },
    {
      "title": "2.ವಿಳಾಸವಿರುವ ಆಧಾರ್ ಕಾರ್ಡ್ ಪ್ರತಿ / Aadhar Card Copy with self-address . ",
      "id": "aadharCard",
      "active": false
    },
    {
      "title": "3. ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಪ್ರತಿ / Copy of the Pan Card . ",
      "id": "panCard",
      "active": false
    },
    {
      "title": "4. ಪಾಸ್ ಪೋರ್ಟ್ ಅಳತೆಯ ಭಾವಚಿತ್ರ – ೨ / Passport picture of the Applicant – 2 No.",
      "id": "passportPic",
      "active": false
    },
    {
      "title": " 5. ಅರ್ಜಿದಾರ ಹಾಗೂ ತಮ್ಮ ಅವಲಂಬಿತರ ಹೆಸರಿನಲ್ಲಿ ಬೇರೆ ಯಾವುದೇ ನಿವೇಶನ \nಹಾಗೂ ಮನೆ ಹೊಂದಿಲ್ಲ ಎಂಬ ಬಗ್ಗೆ ಪ್ರಮಾಣ ಪತ್ರ (ನೋಟರೈಸ್ಡ್) / \n Self-Affidavit duly notarized to show that the applicant\n or their dependents are not owning any site/house.",
      "id": "notorizedSelfAffidavit",
      "active": false
    },
    {
      "title": "6. ಪ್ರಾಧಿಕಾರವು ನಿಗದಿಪಡಿಸಬಹುದಾದ ಶುಲ್ಕ ಪಾವತಿಸುವ ಬಗ್ಗೆ ಒಪ್ಪಿಗೆ ಪ್ರಮಾಣಪತ್ರ \n(ನೋಟರೈಸ್ಡ್)/ Consenting affidavit duly notarized stating that the \n applicant is agreeable to pay allotment rate to be fixed by the BDA . ",
      "id": "regnFeeConsentAffidavit",
      "active": false
    },
    {
      "title": "7. ನೋಂದಾಯಿತ ಕ್ರಯಪತ್ರದ ಧೃಡೀಕೃತ ಪ್ರತಿ / Certified copy of the Registered Sale deed .",
      "id": "saleDeed",
      "active": false
    },
    {
      "title": "8. ನಿವೇಶನ ಖರೀದಿಸಿದ ದಿನಾಂಕದಿಂದ  ಈ ತನಕದ ಋಣಭಾರ /\n ಋಣಭಾರರಾಹಿತ್ಯ ಪತ್ರ (ಇ . ಸಿ) - ನಮೂನೆ - 15 / \n Encumbrance Certificate in form 15 from the date of purchase of revenue site till date .",
      "id": "encumberanceCert",
      "active": false
    },
    {
      "title": "9.ರೆವೆನ್ಯೂ ನಿವೇಶನವಿರುವ ಬಡಾವಣೆಯ ನಕ್ಷೆ \n(ಸರ್ವೆ ನಂಬರ್ ಮತ್ತು ರೆವೆನ್ಯೂ ನಿವೇಶನ ಸಂಖ್ಯೆ ಇರುವ ವಿವರ)/\n Layout plan duly demarcating the revenue site (Sy. No. Village and Revenue Site No.). ",
      "id": "layoutPlan",
      "active": false
    },
    {
      "title": "10. ಸ್ಥಳೀಯ ಸಂಸ್ಥೆಗೆ / ಇಲಾಖೆಗೆ ಸಂಬಂಧಿಸಿದ  ಪಾವತಿಸಿರುವ ಕಂದಾಯ ಪಾವತಿ ರಸೀದಿ/ \n Copy of the latest tax paid to local bodies/concerned department . ",
      "id": "taxPaidReceipt",
      "active": false
    },
    {
      "title": "11. ವಾಸ  ಪ್ರಮಾಣಪತ್ರ / Domicile Certificate . ",
      "id": "domCert",
      "active": false
    },
    {
      "title": "12. ನೋಂದಣಿ ಶುಲ್ಕ ಪಾವತಿಸಿದ ರಸೀದಿ  / Registration Fee paid challan .",
      "id": "regnFeepaidChallan",
      "active":false
    },
    {
      "title": "13. ಫಾರಂ 'A' ನಲ್ಲಿನ ಅಫಿಡವಿಟ್ / Affidavit in Form ‘A’ . ",
      "id": "formAAffidavit",
      "active": false
    },
    {
      "title": "14. ನ್ಯಾಯಾಲಯದಲ್ಲಿ ಸಂಬಂಧಿತ  ರೆವೆನ್ಯೂ ನಿವೇಶನದ \nಕುರಿತು ಯಾವುದೇ ದಾವೆ ಹೂಡಿದ್ದಲ್ಲಿ  ಸದರಿ ದಾವೆಯನ್ನು\n ಹಿಂಪಡೆದಿದ್ದಕ್ಕೆ ಅಫಿಡವಿಟ್ ಹಾಗು ನ್ಯಾಯಾಲಯದ ಪ್ರಮಾಣ ಪತ್ರ  /\n Affidavit & Certified copy from concerned courts\n indicating withdrawal of cases pertaining to the said Revenue site .",
      "id": "courtCaseAffidavit",
      "active": false
    },
    {
      "title": "15. ಇತರೆ ಸಂಬಂಧಿತ ದಾಖಲೆಗಳು / Other Related Documents .",
      "id": "otherRelatedDocs",
      "active": false
    }
  ]
  constructor() { }
}
