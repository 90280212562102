import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";

@Injectable()
export class AlertDialogService {
  private opened = false;

  constructor(private dialog: MatDialog) {}

  openDialog(message: any, onCloseCallback?: any): void {
    if (!this.opened) {
      this.opened = true;
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data: { message },
        maxHeight: "100%",
        width: "540px",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true
      });

      dialogRef.afterClosed().subscribe(() => {
        this.opened = false;
        if(onCloseCallback) {
          onCloseCallback();
        }
      });
    }
  }
}