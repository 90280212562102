<h1 mat-dialog-title>Alert</h1>
<div mat-dialog-content>
	<p class="error-message">
		{{message}}
	</p>
</div>
<div mat-dialog-actions>
	<button mat-raised-button mat-dialog-close color="primary">
    OK
  </button>
</div>