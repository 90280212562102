<div class="row content-area">
            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row">
                    <label class="form-label">Approval No / ಮಂಜೂರಾದ ಸಂಖ್ಯೆ :</label>
                    <input type="text" [(ngModel)]="dcAprovalNumber" class="form-textbox" [readonly] = "(data && data.dcAprovalNumber) && !isEditable"/>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="row application-form-row">
                    <label class="form-label">Date of Approval / ಅನುಮೋದನೆಯ ದಿನಾಂಕ :</label>
                    <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="dcAprovalDate" class="form-textbox" [readonly] = "(data && data.dcAprovalDate) && !isEditable" />
                </div>
            </div>

</div>
        



