import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-approval-of-bbmp',
  templateUrl: './approval-of-bbmp.component.html',
  styleUrls: ['./approval-of-bbmp.component.scss']
})
export class ApprovalOfBbmpComponent implements OnInit {

  @Input() data : any;

  @Input() isEditable : any;

  public bbmpAprovalNumber : string;
  public bbmpAprovalDate : Date;

  public maxDate = new Date().getTime();

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.bbmpAprovalNumber = null;
    this.bbmpAprovalDate = null;
  }


  getPayload(){
    let payload = {};
    payload['bbmpAprovalNumber'] = this.bbmpAprovalNumber;
    payload['bbmpAprovalDate'] = this.bbmpAprovalDate;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.bbmpAprovalNumber = this.data.bbmpAprovalNumber;
    this.bbmpAprovalDate = this.data.bbmpAprovalDate;
   }

}
