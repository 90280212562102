<div class="row content-area">
    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Completion Certificate No / ಕಟ್ಟಡದ ಪೂರ್ಣಗೊಂಡ ಪ್ರಮಾಣಪತ್ರ ಸಂಖ್ಯೆ :</label>
            <input type="text" [(ngModel)]="completionNo" class="form-textbox" [readonly] = "(data && data.completionNumber) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Date / ದಿನಾಂಕ :</label>
            <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="completionDate" class="form-textbox" [readonly] = "(data && data.completionDate) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Issuing Authority / ನೀಡಲಾದ ಕಛೇರಿ :</label>
            <input type="text" [(ngModel)]="issuingAuthority" class="form-textbox" [readonly] = "(data && data.completionAuth) && !isEditable"/>
        </div>
    </div>
</div>

