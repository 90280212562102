import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { UserService } from 'src/services/user-service/user.service';

@Component({
  selector: 'app-field-visit-user-dashboard',
  templateUrl: './field-visit-user-dashboard.component.html',
  styleUrls: ['./field-visit-user-dashboard.component.scss']
})
export class FieldVisitUserDashboardComponent implements OnInit {

  public village: any;
  public user: any;
  public villageId: any;
  public displayedColumns: string[] = ['sn','applicationNo','status'];
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  public dataSource = new MatTableDataSource();

  constructor(private router: Router, private route: ActivatedRoute,public dialog: MatDialog,private userService: UserService,
    private applicationService: ApplicationService,private storageService:StrorageService) { }

  ngOnInit(): void {
    this.user = this.storageService.getUser();
    this.village = this.user['villageDTO'];
    this.villageId = this.village['id'];
    this.router.navigate(['/field-visit-user-dashboard']);
    this.getApplicants();
  }

  public showActivityLog = () => {
    this.router.navigate(['/field-visit-user-activity-log']);
  }
  public getApplicantPaginatorData = (event: PageEvent) => {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getApplicants();
  }

  private getApplicants = () => {
      this.applicationService.getFieldUserApplicants(this.currentPage, this.pageSize).subscribe(res =>{
        this.refreshDataTable(res);
        console.log(res);
        
      });
  }

  private refreshDataTable = (res: any) => {
    this.totalSize = res.totalRecord;
    this.dataSource.data = res.data;
    this.dataSource._updateChangeSubscription();
  }
  onPreviewClick = (id: any) => {
    this.router.navigate(['/field-visit-workbench'], {queryParams:{application_id:id}});
   }

}
