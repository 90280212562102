import { Injectable } from '@angular/core';
import { StrorageService } from '../strorage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

    // USER_TYPE_CONSTANTS
    HELPDESK_USER = "helpdesk_user";
    ONLINE_APPLICATION_USER = "online_application_user";
    COMITTEE_USER = "committee_user";
    SCRUTINY_USER = "scrutiny_user";
    ADMIN_USER = "admin_user";
    AUDIT_HELPDESK_USER = "audit_helpdesk_user";
    FIELD_VISIT_USER = "field_user";

     // STORAGE_KEY_CONSTANTS
    ACCESS_TOKEN_KEY = "access_token_key_";
    USER_TYPE_KEY = "user_type_key";
    USER_NAME_KEY = "user_name_key";
    VILLAGE_NAME_KEY = "village_name_key";

  constructor(private storageService: StrorageService) { }

  getAccessToken(){
    return this.storageService.getItem(this.ACCESS_TOKEN_KEY);
  }

  getUserType(){
    return this.storageService.getItem(this.USER_TYPE_KEY);
  }

  removeUser(){
    this.storageService.removeItem(this.ACCESS_TOKEN_KEY);
    this.storageService.removeItem(this.USER_TYPE_KEY);
    this.storageService.removeItem(this.USER_NAME_KEY);
    this.storageService.removeItem(this.VILLAGE_NAME_KEY);
  }

  saveAccessToken(token){
    return this.storageService.setItem(token, this.ACCESS_TOKEN_KEY);
  }

  saveUserType(type){
    return this.storageService.setItem(type, this.USER_TYPE_KEY);
  }

  saveUserName(name){
    return this.storageService.setItem(name, this.USER_NAME_KEY);
  }

  saveVillageName(villageName){
    return this.storageService.setItem(villageName, this.VILLAGE_NAME_KEY);
  }

  getUserName(){
    return this.storageService.getItem(this.USER_NAME_KEY);
  }

  getVillageName(){
    return this.storageService.getItem(this.VILLAGE_NAME_KEY);
  }
}
