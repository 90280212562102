import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-commencement-certificate',
  templateUrl: './commencement-certificate.component.html',
  styleUrls: ['./commencement-certificate.component.scss']
})
export class CommencementCertificateComponent implements OnInit {

  @Input() data : any;
  @Input() isEditable : any;

  public commencementNo : String;

  public commencementDate : Date;

  public issuingAuthority : string;

  public maxDate = new Date().getTime();

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.commencementNo = null;
    this.commencementDate = null;
    this.issuingAuthority = null;
  }

  getPayload(){
    let payload = {};
    payload['commenceNumber'] = this.commencementNo;
    payload['commenceDate'] = this.commencementDate;
    payload['commenceAuth'] = this.issuingAuthority;
    return payload;
  }


  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.commencementNo = this.data.commenceNumber;
    this.commencementDate = this.data.commenceDate;
    this.issuingAuthority = this.data.commenceAuth;
   }

}
