import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { ApplicationService} from 'src/services/application.service';
import { UserService } from 'src/services/user-service/user.service';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { StrorageService } from 'src/services/strorage.service';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';

@Component({
  selector: 'app-scrutiny-login',
  templateUrl: './scrutiny-login.component.html',
  styleUrls: ['./scrutiny-login.component.scss']
})
export class ScrutinyLoginComponent implements OnInit {

  villages : any[];
  selectedVillages: number;
  password : string;
  mobileNo : number;
  passwordVisibility = 'password';
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  id: any;
  villageName: any;
  public isLoading = false;

  constructor( private applicationService:ApplicationService,private _snackBar: MatSnackBar,
     private userService: UserService, private authService: AuthService, private router: Router, 
     private confirmDialogService: ConfirmDialogService, private roleConfig: RoleConfigService,private storageService:StrorageService) {
      
      }

  ngOnInit(): void {
    this.fetchVillages();
   
  }

  fetchVillages(){
    this.applicationService.fetchAllVillages().subscribe(res=>{
      this.villages = res;
    })
  }

  openConfirmDialog() {
    const options = {
      title: 'Leave page?',
      message: 'By leaving this page you will permanently lose your form changes.',
      cancelText: 'CANCEL',
      confirmText: 'YES, LEAVE PAGE'
    };
    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
        if(confirmed) {
          console.log("confirmes");
        }
    });
  }
  
  login(){
    if(!this.isValidLogin()){
      return;
    }
    this.isLoading=true;
    let payload = {};
    payload['mobile'] = this.mobileNo;
    payload['password'] = this.password;
    payload['villageId']=this.selectedVillages;
    payload['loginType'] = this.roleConfig.ROLE_SCRUTINY_USER;
    this.authService.login(payload, this.loginResHandler);
 }
  
  loginResHandler = (res: any):void => {
    if(res && res.success) {
      this.router.navigate(['/scrutiny-user-dashboard']);
      this.openSnackbar('Login successful', 'Dismiss');
      return;
   }
   this.openSnackbar('Invalid credentials', 'Dismiss');
  }

  isValidLogin() : boolean{
     if(!this.selectedVillages){
       this.openSnackbar('Please select villages', 'Dismiss');
       return false;
     }

    if(!this.mobileNo){
      this.openSnackbar('Please enter mobile number', 'Dismiss');
      return false;
   }
  
    if(!this.password){
      this.openSnackbar('Please enter password', 'Dismiss');
      return false;
   }
  return true;
}

 openSnackbar(message: string, action: string){
  this._snackBar.open(message, action);
}
showPassword(){
  this.passwordVisibility = 'password'
}

hidePassword(){
  this.passwordVisibility = 'text';
}

ngOnDestroy() {
  
}
}
