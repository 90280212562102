import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-water-connection',
  templateUrl: './water-connection.component.html',
  styleUrls: ['./water-connection.component.scss']
})
export class WaterConnectionComponent implements OnInit {

  @Input() data : any;
  @Input() isEditable : any;

  public tempOrderNo : string;

  public tempOrderDate : Date;

  public permOrderNo : string;

  public permOrderDate : Date;

  public maxDate = new Date().getTime();

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.tempOrderNo = null;
    this.tempOrderDate = null;
    this.permOrderNo = null;
    this.permOrderDate = null;
  }

  getPayload(){
    let payload = {};
    payload['bwssbTempNumber'] = this.tempOrderNo;
    payload['bwssbTempDate'] = this.tempOrderDate;
    payload['bwssbPermNumber'] = this.permOrderNo;
    payload['bwssbPermDate'] = this.permOrderDate;
    return payload;
  }

  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.tempOrderNo = this.data.bwssbTempNumber;
    this.tempOrderDate = this.data.bwssbTempDate;
    this.permOrderNo = this.data.bwssbPermNumber;
    this.permOrderDate = this.data.bwssbPermDate;
   }

}
