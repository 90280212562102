<div class ="container">
    <div class="row">
        <!-- <div class="col-sm-10 offset-sm-1" [hidden]="!panchayats || panchayats.length == 0">
            <div class="panchayat-header">
                Choose Panchayat 
            </div>
            <div class="panchayat-options">
                <app-filter-option *ngFor="let panchayat of panchayats; let i = index;"
                 [data]="panchayat" [selectedId]="selectedPanchayat" [color]="colors[i]" (selectOption)="onPanchayatChange($event)" type="panchayat" 
                [hidden]="!panchayat || !panchayat.name"></app-filter-option>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-sm-10 offset-sm-1" [hidden]="!selectedPanchayat">
            <div class="panchayat-header">
            {{this.isScrutinyUser ? 'Villages' : ' Choose Village'}}
            </div>
            <div class="village-options">
                <app-filter-option *ngFor="let village of villages; let i = index;"
                 [data]="village" [color]="colors[i]" [selectedId]="selectVillage" (selectOption)="onVillageChange($event)" type="village"
                ></app-filter-option>
            </div>
        </div> -->
    
    
        <div class ="row container-fluid"style='margin-top:60px'>
            <button mat-raised-button style='margin-left:20px' class="orange">Somashettihalli Panchayat<br>
                Scrutinized:  <br/>
                Pending Scrutiny :<br/>
                Scheduled for Field visit :<br/>
                </button>
            <button mat-raised-button style='margin-left: 50px' class="lightblue">Kasaghattapura Panchayat<br>
                Scrutinized:  <br>
                Pending Scrutiny :<br>
                Scheduled for Field visit:<br>
             </button>
             <button mat-raised-button style='margin-left: 50px' class="yellow">Vaderahalli Panchayat<br>
                Scrutinized:  <br>
                Pending Scrutiny :<br>
                Scheduled for Field visit:<br>
             </button>
             <button mat-raised-button style='margin-left: 50px' class="violet">Singanayakanahalli Panchayat<br>
                Scrutinized:  <br>
                Pending Scrutiny :<br>
                Scheduled for Field visit:<br>
             </button>
        </div>
        <div class ="row container-fluid "style='margin-top:80px'>
            <button mat-raised-button style='margin-left:20px' class="orange">Pre 2008<br>
                Scrutinized:  <br/>
                Pending Scrutiny :<br/>
                Scheduled for Field visit :<br/>
            </button>
            <button mat-raised-button style='margin-left:60px' class="blue">2018-2014<br>
                Scrutinized:  <br/>
                Pending Scrutiny :<br/>
                Scheduled for Field visit :<br/>
            </button>
            <button mat-raised-button style='margin-left:70px' class="yellow">2015 – 3 Aug 2018
                <br>
                    Scrutinized:  <br/>
                    Pending Scrutiny :<br/>
                    Scheduled for Field visit :<br/>
            </button>
            <button mat-raised-button style='margin-left:80px' class="violet">Post 4 Aug 2018<br>
                    Scrutinized:  <br/>
                    Pending Scrutiny :<br/>
                    Scheduled for Field visit :<br/>
            </button>
         </div>
         <div class ="row container-fluid "style='margin-top:100px'>
            <button mat-raised-button style='margin-left:80px' class="green">Plan Approved<br>
                Scrutinized:  <br/>
                Pending Scrutiny :<br/>
                Scheduled for Field visit :<br/>
        </button>  
            <button mat-raised-button style='margin-left:60px' class="red">Plan Not Approved<br>
                Scrutinized:  <br/>
                Pending Scrutiny :<br/>
                Scheduled for Field visit :<br/>
        </button>  
        <button mat-raised-button style='margin-left:60px' class="grey">Plan Not Approved<br>
            Scrutinized:  <br/>
            Pending Scrutiny :<br/>
            Scheduled for Field visit :<br/>
    </button>  
    <div class ="row container-fluid "style='margin-top:100px'>
        <button mat-raised-button style='margin-left:80px' class="lightblue">Plan Complaint<br>
            Scrutinized:  <br/>
            Pending Scrutiny :<br/>
            Scheduled for Field visit :<br/>
    </button>  
        <button mat-raised-button style='margin-left:60px' class="yellow">Plan Violative<br>
            Scrutinized:  <br/>
            Pending Scrutiny :<br/>
            Scheduled for Field visit :<br/>
    </button>  
    </div>
    
    
    </div> 
    </div>
    