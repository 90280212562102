import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { AlertDialogService } from "src/app/shared/alert/alert-dialog.service";
import { ErrorDialogService } from 'src/app/shared/error/error-dialog.service';
import { MessageConstantService } from "src/app/shared/services/messageconstant/message-constant.service";
import { StrorageService } from "src/services/strorage.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private errorDialogService: ErrorDialogService,
    private alertDialogService: AlertDialogService, 
    private zone: NgZone, private storageService: StrorageService, 
    private router: Router, private messageConstantService: MessageConstantService) {}

  handleError(error: Error) {
    console.log(error);
    this.zone.run(() => {
      let errorMsg = error.message || "Something went wrong, contact developer support";
      if(error['dialog_type'] == 'alert') {
        if(error['message_type'] == 'session_expired') {
          this.storageService.clear();
          this.alertDialogService.openDialog(this.messageConstantService.SESSION_EXPIRY_MESSAGE, () => {
            this.router.navigate(['/']);
          });
        }
      } else {
        this.errorDialogService.openDialog(errorMsg);
      }
    })
  }
}