<section>
    <!-- data table -->
    <mat-card>
        <table mat-table [dataSource]="dataSource">
            <!-- Position Column -->
            <ng-container matColumnDef="applicationNo">
                <th mat-header-cell *matHeaderCellDef> Application No. </th>
                <td mat-cell *matCellDef="let element"> {{element.applicationNo}} </td>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="applicantName">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.applicantName}} </td>
            </ng-container>
            
            <!-- Telephone Column -->
            <ng-container matColumnDef="telephoneNumber">
                <th mat-header-cell *matHeaderCellDef> Mobile </th>
                <td mat-cell *matCellDef="let element"> {{element.mobileNumber}} </td>
            </ng-container>

              <!-- Preview Column -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="onPreviewClick(element)" class="preview-link">Preview</a> <span class="vertical-divider">|</span>
                    <a (click)="goDetailsPage(element)" class="enclosure-link">Enclosure</a> 
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
    </mat-card>
</section>