import { Component, OnInit, Inject, Input, EventEmitter, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  
  constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: { message: string }) { }

  ngOnInit(): void {

  }

  public onOkClicked = () => {
    this.dialogRef.close(true);
  }

  public onCancelClicked = () => {
    this.dialogRef.close(false);
  }

  @HostListener("keydown.esc") 
  public onEsc() {
    this.dialogRef.close(false);
  }
}
