import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationModalComponent>,
      @Inject(MAT_DIALOG_DATA, ) public message: string,) { }

  ngOnInit(): void {
  }

  onNegativeClick(): void {
    this.dialogRef.close(false);
  }
  onPositiveClick(): void {
    this.dialogRef.close(true);
  }

}
