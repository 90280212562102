<div class="rowcontent-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="audit"></app-top-bar>
        <app-header></app-header>
        <div class="col-sm-6 offset-sm-3">
            <label class="title">AUDIT - HELPDESK</label>
            <mat-card>
             
                <div class="row">
                    <div class="col-sm-10 offset-sm-1">
                    <label>User Mobile</label>
                </div>
                </div>
                <div class="row">
                    <div class="col-sm-10 offset-sm-1">
                    <input type="number" autocomplete="off" class="form-group" [(ngModel)]="mobile"/>
                </div>
                </div>
                <div class="row"> 
                    <div class="col-sm-10 offset-sm-1">  
                    <label>User Password</label>  
                </div>   
                </div>
                    <div class="row">  
                     <div class="col-sm-10 offset-sm-1 password-field"> 
                            <input [type]="passwordVisibility" [(ngModel)]="password"/>
                            <fa-icon [hidden]="passwordVisibility == 'password'" [icon]="faEye" (click)="showPassword()" class="field-icon" ></fa-icon>
                            <fa-icon [hidden]="passwordVisibility == 'text'" [icon]="faEyeSlash" (click)="hidePassword()" class="field-icon" ></fa-icon>
     
              </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 offset-sm-3">
                        <button mat-raised-button class="btn btn-primary" (click)="onSubmit()" cdkFocusInitial>SUBMIT</button>
                    </div>
                </div>
       
            </mat-card>
        </div>
       
        <app-footer></app-footer>
        </div>
        </div>
