<div class="row content-area">
    <div class="title">1. Sanctions of temporary connection / ತಾತ್ಕಾಲಿಕ ಸಂಪರ್ಕ ಮಂಜೂರಾತಿ  -</div>
    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">RR No / ಆರ್.ಆರ್. ಸಂಖ್ಯೆ :</label>
            <input type="text" [(ngModel)]="tempOrderNo" class="form-textbox" [readonly] = "(data && data.bescomTempNumber) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Date / ದಿನಾಂಕ :</label>
            <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="tempOrderDate" class="form-textbox" [readonly] = "(data && data.bescomTempDate) && !isEditable" />
        </div>
    </div>


    <div class="title">2. Sanctions of permanent connection / ಖಾಯಂ ವಿದ್ಯುತ್ ಮಂಜೂರಾತಿ -</div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">RR No / ಆರ್.ಆರ್. ಸಂಖ್ಯೆ :</label>
            <input type="text" [(ngModel)]="permOrderNo" class="form-textbox" [readonly] = "(data && data.bescomPermNumber) && !isEditable"/>
        </div>
    </div>

    <div class="col-sm-12 col-md-12">
        <div class="row application-form-row ">
            <label class="form-label">Date / ದಿನಾಂಕ :</label>
            <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="permOrderDate" class="form-textbox" [readonly] = "(data && data.bescomPermDate) && !isEditable"/>
        </div>
    </div>

</div>

