import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  public message:string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string; }
  ) {
    this.message = data.message;
  }

  ngOnInit(): void {
  }

}
