import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-occupancy-certificate',
  templateUrl: './occupancy-certificate.component.html',
  styleUrls: ['./occupancy-certificate.component.scss']
})
export class OccupancyCertificateComponent implements OnInit {

  @Input() data : any;
  @Input() isEditable : any;

  public occupancyNo : string;

  public occupancyDate : Date;

  public issuingAuthority : string;

  public maxDate = new Date().getTime();

  constructor() { }

  ngOnInit(): void {
  }

  reset(){
    this.occupancyNo = null;
    this.occupancyDate = null;
    this.issuingAuthority = null;
  }

  getPayload(){
    let payload = {};
    payload['occupancyNumber'] = this.occupancyNo;
    payload['occupancyDate'] = this.occupancyDate;
    payload['occupancyAuth'] = this.issuingAuthority;
    return payload;
  }


  ngOnChanges(data): void{
    if(!this.data){
      return;
    }
    this.occupancyNo = this.data.occupancyNumber;
    this.occupancyDate = this.data.occupancyDate;
    this.issuingAuthority = this.data.occupancyAuth;
   }

}
